/* -- File : FluidProperties/index.js */
/* Author : SLK */
/* Description : which contains all fluid properties reducers */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import * as constants from "./constant";

/* Dropdown values initial state */
const dropDownValues = {
  liquid: [
    { value: "1", description: "Water" },
    { value: "2", description: "Methan" },
    { value: "3", description: "Propane" },
    { value: "4", description: "Typical Crude oil" },
    { value: "5", description: "Other" }
  ],
  gas: [
    { value: "1", description: "Steam" },
    { value: "2", description: "Air" }
  ],
  showLoader: false
};

/* Fluid properties list reducer */
const getFluidPropertiesListValues = (
  state = dropDownValues,
  action = null
) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    /* Liquid Success case */
    case constants.GET_LIQUID_LIST_SUCCESS:
      return {
        ...state,
        liquid: action.liquidData,
        showLoader: false
      };
    /* Gas Success case */
    case constants.GET_GAS_LIST_SUCCESS:
      return {
        ...state,
        gas: action.gasData,
        showLoader: false
      };
    /* loading case */
    case constants.GET_FLUIDPROPERTIES_LIST_LOADING:
      return {
        ...state,
        showLoader: true
      };
    /* Error case */
    case constants.GET_FLUIDPROPERTIES_LIST_ERROR:
      return {
        ...state,
        showLoader: false
      };

    default:
      break;
  }
  /* Returning updated state */
  return state;
};

export { getFluidPropertiesListValues };
