/* -- File : FluidProperties/api.js */
/* -- Author : SLK -- */
/* -- Description : API file which contains fluid properties API calls -- */
/* -- Year : 2019 -- */
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import axios from "axios";
import { API_URL } from "../../utils/constant";

/* Axios instance creation */
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  },
  timeout: 240000
});

/* API function to get liquid names */
export const fetchFluidPropertiesListLiquidValuesAPI = input => {
  return axiosInstance
    .post("liquidNamesView/", input)
    .then(response => response.data);
};

/* API function to get gas names */
export const fetchFluidPropertiesListGasValuesAPI = input => {
  return axiosInstance
    .post("gasNamesView/", input)
    .then(response => response.data);
};
