// @flow

/* -- File : Header.js */
/* Author : SLK */
/* Component Name : Header */
/* Component Type : Function Component */
/* Description : Header component is a functional component, Some info comes in (props), and returns application header part as output */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React from "react";
import emerson from "../../images/Emerson_Logo.png";
import headerCSS from "./Header.module.scss";
import { Dropdown } from "../Dropdown/Dropdown";

/* Component props type declaration */
type HeaderProps = {
  onChangeDrodown: any,
  labelsData: any,
  langDropDownValue: any
};

/* function for header component */
/* Which Return the html code of the component */
function Header({
  onChangeDrodown,
  labelsData,
  langDropDownValue
}: HeaderProps) {
  return (
    <header data-test="headerTest">
      <div className="container">
        <div className={headerCSS.logo}>
          {" "}
          <img data-test="imageTest" src={emerson} alt="Logo" />{" "}
        </div>
        <div>
          {" "}
          <h4>{labelsData.SSA_APP_NAME}</h4>{" "}
        </div>
        <div className={headerCSS.login_user_div}>
          <div>
            <label> {labelsData.SSA_LANGUAGE} </label>
          </div>
          <div>
            <Dropdown
              name="languageDropdown"
              classes="langDropDown"
              options={[
                { value: "en_US", description: "en_US" },
                { value: "de_DE", description: "de_DE" }
              ]}
              value={langDropDownValue}
              changedStateValue={stateValue => onChangeDrodown(stateValue)}
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
