/* -- File : main.js -- */
/* Author : SLK */
/* Description : Main.js file which contains creation of store with saga middleware */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootReducer from "./rootReducer";

import rootSaga from "./rootSaga";

/* saga middleware */
const middleWare = [];
const sagaMiddleware = createSagaMiddleware();
middleWare.push(sagaMiddleware);

/* state logger */
const logger = createLogger();
middleWare.push(logger);

/* store creation */
const store = createStore(rootReducer, compose(applyMiddleware(...middleWare)));
sagaMiddleware.run(rootSaga);

export { store };
