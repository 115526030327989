/* -- File : index.js -- */
/*-- Author : SLK --*/
/*-- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import 'url-search-params-polyfill';
// import 'jquery/dist/jquery.min.js';
// import 'popper.js/dist/popper.js';
// import 'bootstrap/dist/js/bootstrap.min.js';
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
