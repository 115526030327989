/* -- File : errorBoundary.js */
/* Author : SLK */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React, { Component } from "react";

// Error boundary class
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // Life cycle method
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  /* Render method */
  /* Which Return the html code of the component */
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h4 className="fallbackUIDiv">
          Something went wrong, please try after sometime.
        </h4>
      );
    }
    return this.props.children;
  }
}
