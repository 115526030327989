/* -- File : App.js -- */
/* Author : SLK */
/* Description : App file which contains routes tag and provider to pass store */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import ReactGA from "react-ga";
import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import Routes from "./routes";
import { store } from "./store/main";
import "intl";
import "intl/locale-data/jsonp/en";

// App-Insights
import { ai } from "./utils/TelemetryService";

const history = createBrowserHistory({ basename: "" });
ai.initialize({ history });

function initializeReactGA() {
  ReactGA.initialize(process.env.REACT_APP_GA_CODE);
  ReactGA.pageview("/sizing");
}

/* App Function */
function App() {
  initializeReactGA();
  return (
    <Provider data-test="appRenderedTest" store={store}>
      <Routes/>
    </Provider>
  );
}

export default App;
