// @flow
/* -- File : PhysicalDetails.js */
/* Author : SLK */
/* Description : Physical details container which contains physical details UI and functions */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React from "react";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { ErrorBoundary } from "../../components/errorBoundary";

/* -- component props type -- */
type PhysicalDetailsProps = {
  data: {
    pipeSize: string,
    pressureValue: string,
    trimType: string
  },
  pipeSizeList: Array<{
    value: string,
    description: string,
    isReadonly?: boolean
  }>,
  pressureClassList: Array<{
    value: string,
    description: string,
    isReadonly?: boolean
  }>,
  trimTypeList: Array<{
    value: string,
    description: string,
    isReadonly?: boolean
  }>,
  onChange: any,
  labels: any
};

/* Physical details return function */
/* Which Return the html code of the component */
const PhysicalDetails = ({
  data,
  onChange,
  pipeSizeList,
  pressureClassList,
  trimTypeList,
  labels
}: PhysicalDetailsProps) => {
  return (
    <ErrorBoundary>
      <div data-test="physicalDetailsTest">
        {/* -- pipe size dropdown -- */}
        <label> {labels.SSA_PIPE_SIZE} </label>
        <Dropdown
          name="pipeSize"
          options={pipeSizeList}
          value={data.pipeSize}
          classes={"text-uppercase pipeSizeDropdown"}
          changedStateValue={stateValue => onChange("pipeSize", stateValue)}
        />

        {/* -- pressure class dropdown -- */}
        <label> {labels.SSA_PRESSURE_CLASS} </label>
        <Dropdown
          name="pressureValue"
          options={pressureClassList}
          value={data.pressureValue}
          classes={"text-uppercase pressureValueDropdown"}
          changedStateValue={stateValue =>
            onChange("pressureValue", stateValue)
          }
          labelPipe={labels.SSA_PIPE_TOO_LARGE}
        />

        {/* -- trim type dropdown -- */}
        <label> {labels.SSA_TRIM_TYPE} </label>
        <Dropdown
          name="trimType"
          options={trimTypeList}
          value={data.trimType}
          classes={"text-uppercase"}
          changedStateValue={stateValue => onChange("trimType", stateValue)}
        />
      </div>
    </ErrorBoundary>
  );
};

export default PhysicalDetails;
