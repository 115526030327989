// @flow
/* -- File : Home.js */
/* Author : SLK */
/* Description : Home container which contains all other container and components */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../components/Header/Header";
import "./Home.scss";
import PhysicalDetails from "../PhysicalDetails/PhysicalDetails";
import Process from "../Process/Process";
import { FluidProperties } from "../FluidProperties/FluidProperties";
import Button from "../../components/Button/Button";
import { ErrorBoundary } from "../../components/errorBoundary";
import Result from "../Result/Result";
import Loader from "../../components/Loader/Loader";
import facebook from "../../images/footer/facebook-icon-png-data-193532.png";
import linkedin from "../../images/footer/linkedin-icon-png-data-193536.png";
import twitter from "../../images/footer/twitter-icon-png-data-193534.png";
import youtube from "../../images/footer/youtube-icon-png-data-193538.png";

/* -- DropDownObj props type -- */
type DropDownObj = {
  value: string,
  description: string
};

/* -- PhysicalDetailsDropDownObj props type -- */
type PhysicalDetailsDropDownObj = {
  value: string,
  description: string,
  isReadonly?: boolean
};

/* -- physicalDetailsDropdownValuesType props type -- */
type physicalDetailsDropdownValuesType = {
  pipeSize: Array<PhysicalDetailsDropDownObj>,
  pressureValue: Array<PhysicalDetailsDropDownObj>,
  trimType: Array<PhysicalDetailsDropDownObj>,
  showLoader: boolean
};

/* -- fluidPropertiesDropdownValuesType props type -- */
type fluidPropertiesDropdownValuesType = {
  liquid: Array<DropDownObj>,
  gas: Array<DropDownObj>,
  showLoader: boolean
};

/* -- uomListValuesType props type -- */
type uomListValuesType = {
  density: Array<DropDownObj>,
  pressure: Array<DropDownObj>,
  flowrateLiquid: Array<DropDownObj>,
  flowrateGas: Array<DropDownObj>,
  temperature: Array<DropDownObj>,
  noiseLimit: Array<DropDownObj>,
  pressureDrop: Array<DropDownObj>,
  showLoader: boolean
};

/* -- Home component props type -- */

//  loadPhysicalDetailsListValues: Function to get physical details list value,
//  physicalDetailsDropdownValues: Contains physical details dropdown value ,
//  loadLabels: Function to get label list,
//  labelsData: Contains labels data ,
//  fluidPropertiesDropdownValues: Contains fluid properties dropdown values,
//  UOMListValues: Contains UOM list value,
//  defaultData: Contains default data,
//  loadFluidPropertiesListValues: Function to get fluid properties list values,
//  loadUOMLists: Function load UOM list,
//  loadDefaultData: Function to load default data ,
//  changeDefaultData: Function to load change default data,
//  getPressureUOMConversion: Function to get pressure UOM conversion data,
//  calculateInput: Contains calculated input data,
//  changePressureClassList: Function to change pressure class dropdown value,
//  getTemperatureUOMConversion: Function to get temperature UOM conversion data,
//  getPressureDropUOMConversion: Function to get pressure drop UOM conversion data,
//  getDensityUOMConversion: Function to get density UOM conversion data,
//  getFluidConstants: Function to get fluid constant data,
//  getFlowRateUOMConversion: Function to get flow rate UOM conversion data,
//  sendMail: Function to send mail,
//  changeMailSentDetails: Function to change mail sent status,
//  mailSentDetails: Contains mail sent details,
//  valveCapcityData: contains valve capacity data,
//  getRequiredValveCapacityFunction: Function to get required valve capacity,
//  convertDensitySpecificGravity: Function to get density specific gravity,
//  calculateGasDensityOnCompressibilityChange: Function to calculated density on compressibility change,
//  updateInputErrors: Function to update the input errors,
//  inputErrors: Contains input errors,
//  onInputErrorUpdate: Function to change input error,
//  getLanguageList: Function to get language list data,
//  languageList: Contains language list,
//  location: Contains location information

type HomeProps = {
  loadPhysicalDetailsListValues: Function,
  physicalDetailsDropdownValues: physicalDetailsDropdownValuesType,
  loadLabels: Function,
  labelsData: any,
  fluidPropertiesDropdownValues: fluidPropertiesDropdownValuesType,
  UOMListValues: uomListValuesType,
  defaultData: any,
  loadFluidPropertiesListValues: Function,
  loadUOMLists: Function,
  loadDefaultData: Function,
  changeDefaultData: Function,
  getPressureUOMConversion: Function,
  calculateInput: any,
  changePressureClassList: Function,
  getTemperatureUOMConversion: Function,
  getPressureDropUOMConversion: Function,
  getDensityUOMConversion: Function,
  getFluidConstants: Function,
  getFlowRateUOMConversion: any,
  sendMail: Function,
  changeMailSentDetails: Function,
  mailSentDetails: any,
  valveCapcityData: any,
  getRequiredValveCapacityFunction: Function,
  convertDensitySpecificGravity: Function,
  calculateGasDensityOnCompressibilityChange: Function,
  updateInputErrors: Function,
  inputErrors: any,
  onInputErrorUpdate: any,
  getLanguageList: Function,
  languageList: any,
  location: any
};

/* -- component state type -- */
type HomeState = {
  pageName: string,
  lang: string,
  resultEmailID: string,
  isShareResultModalOpen: any
};

/* -- component class -- */
class Home extends Component<HomeProps, HomeState> {
  /* variable to check input field value is changed when blur function triggers */
  inputChanged = false;

  emailValid = true;

  /* -- component constructor -- */
  constructor(props: HomeProps) {
    super(props);
    /* Initial state values */
    this.state = {
      pageName: "physicalDetails",
      lang: "en_US",
      isShareResultModalOpen: false,
      resultEmailID: ""
    };
  }

  /* -- component life cycle method --*/
  /* -- which calls after component render -- */
  componentDidMount() {
    /* Physical details drop down value API call */
    this.props.loadPhysicalDetailsListValues("en_US");
    /* labels value API call */
    this.props.loadLabels("en_US");
    /* Fluid properties dropdown down value API call */
    this.props.loadFluidPropertiesListValues("en_US");
    /* UOM dropdown values API call */
    this.props.loadUOMLists();
    /* Default data API call */
    this.props.loadDefaultData();
    /* Calling language list api to get list */
    this.props.getLanguageList();

    const params = new URLSearchParams(this.props.location.search);
    const lang = params.get("language");
    if (lang !== "" && lang !== undefined && lang !== null) {
      const result = this.props.languageList.listValue.filter(element => {
        return element.locale === lang;
      });
      if (result !== undefined && result.length !== 0) {
        this.changeLanguage(lang);
      } else {
        this.changeLanguage("en_US");
      }
    }
  }

  /* -- Function to set state of selected page name  -- */
  /* Which calls when right side accordion changes or click of next and previous button */
  changePage(pageName: string) {
    this.setState({ pageName });
  }

  /* -- Function to call all the dropdown values API  -- */
  /* Which calls when language changes */
  changeLanguage(dropdownValue: string) {
    this.props.loadLabels(dropdownValue);
    this.props.loadPhysicalDetailsListValues(dropdownValue);
    this.setState({ lang: dropdownValue });
    this.props.loadFluidPropertiesListValues(dropdownValue);
  }

  /* -- Function to check any error for process page  -- */
  /* Which calls when any change in process fields */
  checkProcessError() {
    const { defaultData, calculateInput, inputErrors } = this.props;

    if (
      parseFloat(calculateInput.upstreamPressureP1) <= 0 ||
      (parseFloat(calculateInput.downStreamPressureP2) <= 0 &&
        defaultData.isDownstreamP2 === "true") ||
      (parseFloat(calculateInput.upstreamPressureP1) <=
        parseFloat(calculateInput.downStreamPressureP2) &&
        defaultData.isDownstreamP2 === "true") ||
      (parseFloat(calculateInput.pressureChange) <= 0 ||
        (parseFloat(calculateInput.upstreamPressureP1) <=
          parseFloat(calculateInput.pressureChange) &&
          defaultData.isDownstreamP2 === "false")) ||
      parseFloat(calculateInput.temperatureT1) <= 0 ||
      inputErrors.upstreamPressureP1ErrorMessage !== "" ||
      (inputErrors.downStreamPressureP2ErrorMessage !== "" &&
        defaultData.isDownstreamP2 === "true") ||
      (inputErrors.pressureChangeErrorMessage !== "" &&
        defaultData.isDownstreamP2 === "false") ||
      inputErrors.temperatureT1ErrorMessage !== "" ||
      inputErrors.noiseLimitErrorMessage !== ""
    ) {
      return true;
    }
    return false;
  }

  /* -- Function to check any error for fluid properties page  -- */
  /* Which calls when any change in fluid properties fields */
  checkFluidPropertiesError() {
    const { defaultData, calculateInput, inputErrors } = this.props;

    if (
      (defaultData.selectedTab === "1" &&
        (calculateInput.vaporPressurePV <= 0 ||
          calculateInput.criticalPressurePC <= 0 ||
          calculateInput.densityLiquid <= 0 ||
          calculateInput.flowrate <= 0 ||
          (defaultData.isDensity === "true" &&
            defaultData.densityLiquidError !== undefined &&
            defaultData.densityLiquidError !== "") ||
          (defaultData.isDensity === "false" &&
            defaultData.specificGravityError !== undefined &&
            defaultData.specificGravityError !== "") ||
          (defaultData.vaporPressurePVError !== undefined &&
            defaultData.vaporPressurePVError !== "") ||
          inputErrors.flowrateErrorMessage !== "" ||
          inputErrors.densityLiquidErrorMessage !== "" ||
          inputErrors.specificGravityErrorMessage !== "" ||
          inputErrors.vaporPressurePVErrorMessage !== "" ||
          inputErrors.criticalPressurePCErrorMessage !== "")) ||
      (defaultData.selectedTab === "2" &&
        (calculateInput.flowrateGas <= 0 ||
          calculateInput.densityGas <= 0 ||
          (defaultData.isDensityGas === "true" &&
            defaultData.densityGasError !== undefined &&
            defaultData.densityGasError !== "") ||
          (defaultData.isDensityGas === "false" &&
            defaultData.compressibilityError !== undefined &&
            defaultData.compressibilityError !== "") ||
          parseFloat(defaultData.compressibility) <= 0 ||
          parseFloat(defaultData.molecularWeight) <= 0 ||
          inputErrors.densityGasErrorMessage !== "" ||
          inputErrors.compressibilityErrorMessage !== "" ||
          inputErrors.molecularWeightErrorMessage !== ""))
    ) {
      return true;
    }
    return false;
  }

  /* Function to toggle the result modal state */
  /* Calls on click on share result button */
  modalToggle() {
    this.setState(prevState => ({
      isShareResultModalOpen: !prevState.isShareResultModalOpen
    }));
    this.props.changeMailSentDetails({ message: "Refresh" });
  }

  /* Function to get the significant number */
  /* Calls when binding data in the right side accordion */
  roundSig(num: string) {
    if (num !== "") {
      const data = parseFloat(num);
      return parseFloat(data.toPrecision(2));
    }
    return "--";
  }

  /* Render method */
  /* Which Return the html code of the component */
  render() {
    /* Variables used in navigation */
    let page;
    let label;
    let nextBtn;
    let prevBtn;
    const {
      physicalDetailsDropdownValues,
      labelsData,
      fluidPropertiesDropdownValues,
      UOMListValues,
      defaultData,
      calculateInput,
      mailSentDetails,
      valveCapcityData,
      inputErrors
    } = this.props;

    /* -- condition to render pages on selection of right side navbar  -- */
    if (this.state.pageName === "physicalDetails") {
      /* Loading Physical details component */
      page = (
        <div className="step1" ref="stepwizard-step-1">
          <PhysicalDetails
            data={defaultData}
            pipeSizeList={physicalDetailsDropdownValues.pipeSize}
            pressureClassList={physicalDetailsDropdownValues.pressureValue}
            trimTypeList={physicalDetailsDropdownValues.trimType}
            onChange={this.capturePhysicalDetails}
            labels={labelsData}
          />
        </div>
      );
      label = labelsData.SSA_PHYSICAL_DETAILS;
      nextBtn = "process";
      prevBtn = "";
    } else if (this.state.pageName === "process") {
      /* Loading process component */
      page = (
        <div className="step2" ref="stepwizard-step-2">
          <Process
            data={defaultData}
            onChangeInput={this.processInputChange}
            onUomChange={this.onUOMChange}
            labels={labelsData}
            labelChange={this.processLabelChange}
            UOMListValues={UOMListValues}
            calculateInput={calculateInput}
            onBlurInput={e => {
              this.uomConversionFunction(
                e.target.name,
                e.target.value,
                this.props.defaultData[`${e.target.name}Uom`]
              );
            }}
            inputErrors={inputErrors}
          />
        </div>
      );
      label = labelsData.SSA_PROCESS;
      nextBtn = "fluidProperties";
      prevBtn = "physicalDetails";
    } else if (this.state.pageName === "fluidProperties") {
      /* Loading fluid properties component */
      page = (
        <div className="step3" ref="stepwizard-step-3">
          <FluidProperties
            data={defaultData}
            onChangeInput={this.fluidPropertiesInputChange}
            onUomChange={this.onUOMChange}
            tabChange={this.fluidPropertiesTabChange}
            labels={labelsData}
            labelChange={this.fluidPropertiesLabelChange}
            dropdownValues={fluidPropertiesDropdownValues}
            onChange={this.captureFluidPropertiesDropdownValues}
            UOMListValues={UOMListValues}
            calculateInput={calculateInput}
            onBlurInput={e =>
              this.uomConversionFunction(
                e.target.name,
                e.target.value,
                this.props.defaultData[`${e.target.name}Uom`]
              )
            }
            inputErrors={inputErrors}
            clearError={this.clearError}
          />
        </div>
      );
      label = labelsData.SSA_FLUID_PROPERTIES;
      nextBtn = "results";
      prevBtn = "process";
    } else {
      /* Loading result component */
      page = (
        <div className="step4" ref="stepwizard-step-4">
          <Result
            labels={labelsData}
            isModalOpen={this.state.isShareResultModalOpen}
            modalToggle={() => {
              this.modalToggle();
            }}
            onChangeInput={this.emailInputChange}
            emailID={this.state.resultEmailID}
            sendMail={this.sendMail}
            mailSentDetails={mailSentDetails}
            valveCapcityData={valveCapcityData}
            processInputError={this.checkProcessError()}
            fluidPropertiesInputError={this.checkFluidPropertiesError()}
            calculateInput={calculateInput}
          />
        </div>
      );
      label = labelsData.SSA_RESULTS;
      nextBtn = "";
      prevBtn = "fluidProperties";
    }

    return (
      <ErrorBoundary>
        {/* --- condition to show loader --- */}
        {labelsData.showLoader === true ||
        physicalDetailsDropdownValues.showLoader === true ||
        defaultData.showLoader === true ||
        calculateInput.showLoader === true ||
        mailSentDetails.showLoader === true ? (
          <Loader />
        ) : (
          ""
        )}

        <div data-test="HomeModelTest">
          {/* --- loading header component --- */}
          <Header
            onChangeDrodown={dropdownValue =>
              this.changeLanguage(dropdownValue)
            }
            labelsData={labelsData}
            langDropDownValue={this.state.lang}
          />
          {/* App name section */}
          <div className="container">
            <div className="row">
              <div className="col-6">
                <span className="headingText">{labelsData.SSA_APP_NAME}</span>
              </div>
              <div className="col-6 padd-right-0">
                <Button
                  classname="btn btn-primary btn-next pull-right resBtn1"
                  text={labelsData.SSA_SHARE_RESULT}
                  onclickevent={() => {
                    this.modalToggle();
                    this.setState({ resultEmailID: "" });
                  }}
                />
              </div>
            </div>
          </div>

          {/* --- main container to render pages based on selection  --- */}
          <div className="container main-container">
            <div className="row">
              <div className="col-md-12 col-lg-8 px-0">
                <label className="panel-header-label">{label}</label>

                {page}
                {/* Next Button */}
                {nextBtn !== "" ? (
                  <Button
                    classname="btn btn-primary btn-next pull-right nextBtn"
                    onclickevent={() => {
                      if (
                        (valveCapcityData.showLoader !== true &&
                          defaultData.showLoader !== true &&
                          this.state.pageName === "fluidProperties") ||
                        this.state.pageName !== "fluidProperties"
                      ) {
                        this.changePage(nextBtn);
                      }
                    }}
                    text={labelsData.SSA_NEXT}
                  />
                ) : (
                  ""
                )}
                {/* Previous Button */}
                {prevBtn !== "" ? (
                  <Button
                    classname="btn btn-primary btn-next prevBtn"
                    onclickevent={() => this.changePage(prevBtn)}
                    text={labelsData.SSA_PREVIOUS}
                  />
                ) : (
                  ""
                )}
                {/* Share result button , which display only if page is result */}
                {this.state.pageName === "results" && (
                  <>
                    <Button
                      classname="btn btn-primary btn-next pull-right resBtn"
                      text={labelsData.SSA_SHARE_RESULT}
                      onclickevent={() => {
                        this.modalToggle();
                        this.setState({ resultEmailID: "" });
                      }}
                    />
                  </>
                )}
              </div>
              {/* Accordion Section */}
              <div className="col-md-12 col-lg-4 pr-0">
                {/* Accordion Ul */}
                <ul className="accordionUl">
                  {/* ---------------------- PHYSICAL DETAILS SECTION --------------------*/}
                  <li
                    className={classnames(
                      {
                        "active-accordion":
                          label === labelsData.SSA_PHYSICAL_DETAILS
                      },
                      { physicalPropertiesLi: true }
                    )}
                    onClick={() => this.changePage("physicalDetails")}
                  >
                    <label>{labelsData.SSA_PHYSICAL_DETAILS}</label>
                    <div>
                      <span>
                        {" "}
                        {labelsData.SSA_PIPE_SIZE} :{" "}
                        {/* Displaying pipe size value */}
                        {physicalDetailsDropdownValues.pipeSize.map(option => {
                          return defaultData.pipeSize === option.value
                            ? option.description
                            : null;
                        })}
                      </span>
                      <br />
                      <span>
                        {" "}
                        {labelsData.SSA_PRESSURE_CLASS} :{" "}
                        {defaultData.pressureValue}{" "}
                      </span>
                      <br />
                      <span>
                        {" "}
                        {labelsData.SSA_TRIM_TYPE} :{" "}
                        {/* Displaying trim type value */}
                        {physicalDetailsDropdownValues.trimType.map(option => {
                          return defaultData.trimType === option.value
                            ? option.description
                            : null;
                        })}
                      </span>
                    </div>
                  </li>
                  {/* ---------------------- END OF PHYSICAL DETAILS SECTION --------------------*/}

                  {/* ---------------------- PROCESS SECTION --------------------*/}
                  <li
                    className={classnames(
                      { "active-accordion": label === labelsData.SSA_PROCESS },
                      { containsError: this.checkProcessError() === true },
                      { processLi: true }
                    )}
                    onClick={() => this.changePage("process")}
                  >
                    <label>{labelsData.SSA_PROCESS}</label>
                    <div>
                      <span
                        className={
                          parseFloat(calculateInput.upstreamPressureP1) <= 0 ||
                          ((parseFloat(calculateInput.upstreamPressureP1) <=
                            parseFloat(calculateInput.downStreamPressureP2) &&
                            defaultData.isDownstreamP2 === "true") ||
                          (parseFloat(calculateInput.upstreamPressureP1) <=
                            parseFloat(calculateInput.pressureChange) &&
                            defaultData.isDownstreamP2 === "false") ||
                          this.props.inputErrors
                            .upstreamPressureP1ErrorMessage !== ""
                            ? "errorValue"
                            : undefined)
                        }
                      >
                        {" "}
                        {labelsData.SSA_UP_PRESSURE} :{" "}
                        {this.roundSig(defaultData.upstreamPressureP1)}{" "}
                        {defaultData.upstreamPressureP1Uom}{" "}
                      </span>
                      <br />

                      {defaultData.isDownstreamP2 === "true" ? (
                        <span
                          className={
                            parseFloat(calculateInput.downStreamPressureP2) <=
                              0 ||
                            parseFloat(calculateInput.upstreamPressureP1) <=
                              parseFloat(calculateInput.downStreamPressureP2) ||
                            this.props.inputErrors
                              .downStreamPressureP2ErrorMessage !== ""
                              ? "errorValue"
                              : undefined
                          }
                        >
                          {labelsData.SSA_DOWN_PRESSURE} :{" "}
                          {this.roundSig(defaultData.downStreamPressureP2)}{" "}
                          {defaultData.downStreamPressureP2Uom}
                        </span>
                      ) : (
                        <span
                          className={
                            (parseFloat(calculateInput.pressureChange) <= 0 ||
                              parseFloat(calculateInput.upstreamPressureP1) <=
                                parseFloat(calculateInput.pressureChange) ||
                              this.props.inputErrors
                                .pressureChangeErrorMessage !== "") &&
                            "errorValue"
                          }
                        >
                          {labelsData.SSA_PRESSURE_CHANGE} :{" "}
                          {this.roundSig(defaultData.pressureChange)}{" "}
                          {defaultData.pressureChangeUom}
                        </span>
                      )}

                      <br />
                      <span
                        className={
                          parseFloat(calculateInput.temperatureT1) <= 0 ||
                          this.props.inputErrors.temperatureT1ErrorMessage !==
                            ""
                            ? "errorValue"
                            : undefined
                        }
                      >
                        {" "}
                        {labelsData.SSA_TEMPERATURE} :{" "}
                        {this.roundSig(defaultData.temperatureT1)}{" "}
                        {defaultData.temperatureT1Uom}{" "}
                      </span>
                      <br />
                      <span
                        className={
                          this.props.inputErrors.noiseLimitErrorMessage !== ""
                            ? "errorValue"
                            : undefined
                        }
                      >
                        {" "}
                        {labelsData.SSA_NOISE_LIMIT} :{" "}
                        {this.roundSig(defaultData.noiseLimit)}{" "}
                        {defaultData.noiseLimitUom}{" "}
                      </span>
                    </div>
                  </li>
                  {/* ---------------------- END OF PROCESS SECTION --------------------*/}

                  {/* ---------------------- FLUID PROPERTIES SECTION --------------------*/}
                  <li
                    className={classnames(
                      {
                        "active-accordion":
                          label === labelsData.SSA_FLUID_PROPERTIES
                      },
                      {
                        containsError: this.checkFluidPropertiesError() === true
                      },
                      { fluidPropertiesLi: true }
                    )}
                    onClick={() => this.changePage("fluidProperties")}
                  >
                    <label>{labelsData.SSA_FLUID_PROPERTIES}</label>
                    <div>
                      {defaultData.selectedTab === "1" ? (
                        <div>
                          <b> {labelsData.SSA_LIQUID} </b>
                          <br />
                          <span>
                            {" "}
                            {labelsData.SSA_LIQUID_NAME} :{" "}
                            {fluidPropertiesDropdownValues.liquid.map(
                              (option, index) => {
                                return defaultData.liquidName === option.value
                                  ? option.description
                                  : null;
                              }
                            )}
                          </span>
                          <br />
                          <span
                            className={
                              calculateInput.flowrate <= 0 ||
                              inputErrors.flowrateErrorMessage !== ""
                                ? "errorValue"
                                : undefined
                            }
                          >
                            {" "}
                            {labelsData.SSA_FLOW_RATE} :{" "}
                            {this.roundSig(defaultData.flowrate)}{" "}
                            {defaultData.flowrateUom}{" "}
                          </span>
                          <br />

                          {defaultData.isDensity === "true" ? (
                            <span
                              className={
                                calculateInput.densityLiquid <= 0 ||
                                ((defaultData.densityLiquidError !==
                                  undefined &&
                                  defaultData.densityLiquidError !== "") ||
                                  inputErrors.densityLiquidErrorMessage !== "")
                                  ? "errorValue"
                                  : undefined
                              }
                            >
                              {labelsData.SSA_DENSITY} :{" "}
                              {defaultData.densityLiquidError !== undefined &&
                              defaultData.densityLiquidError !== "" ? (
                                this.props.labelsData[
                                  defaultData.densityLiquidError
                                ]
                              ) : (
                                <>
                                  {this.roundSig(defaultData.densityLiquid)}{" "}
                                  {defaultData.densityLiquidUom}
                                </>
                              )}
                            </span>
                          ) : (
                            <span
                              className={
                                (defaultData.specificGravityError !==
                                  undefined &&
                                  defaultData.specificGravityError !== "") ||
                                parseFloat(defaultData.specificGravity) <= 0 ||
                                inputErrors.specificGravityErrorMessage !== ""
                                  ? "errorValue"
                                  : undefined
                              }
                            >
                              {labelsData.SSA_SPECIFIC_GRAVITY} :{" "}
                              {defaultData.specificGravityError !== undefined &&
                              defaultData.specificGravityError !== ""
                                ? this.props.labelsData[
                                    defaultData.specificGravityError
                                  ]
                                : this.roundSig(
                                    defaultData.specificGravity
                                  )}{" "}
                            </span>
                          )}

                          <br />
                          <span
                            className={
                              calculateInput.vaporPressurePV <= 0 ||
                              ((defaultData.vaporPressurePVError !==
                                undefined &&
                                defaultData.vaporPressurePVError !== "") ||
                                inputErrors.vaporPressurePVErrorMessage !== "")
                                ? "errorValue"
                                : undefined
                            }
                          >
                            {" "}
                            {labelsData.SSA_VAPOR_PRESSURE} Pv :{" "}
                            {defaultData.vaporPressurePVError !== undefined &&
                            defaultData.vaporPressurePVError !== "" ? (
                              this.props.labelsData[
                                defaultData.vaporPressurePVError
                              ]
                            ) : (
                              <>
                                {this.roundSig(defaultData.vaporPressurePV)}{" "}
                                {defaultData.vaporPressurePVUom}
                              </>
                            )}{" "}
                          </span>
                          <br />
                          <span
                            className={
                              calculateInput.criticalPressurePC <= 0 ||
                              inputErrors.criticalPressurePCErrorMessage !== ""
                                ? "errorValue"
                                : undefined
                            }
                          >
                            {" "}
                            {labelsData.SSA_CRITICAL_PRESSURE} Pc :{" "}
                            {this.roundSig(defaultData.criticalPressurePC)}{" "}
                            {defaultData.criticalPressurePCUom}{" "}
                          </span>
                          <br />
                        </div>
                      ) : (
                        <div>
                          <b> {labelsData.SSA_GAS} </b>
                          <br />
                          <span>
                            {" "}
                            {labelsData.SSA_GAS_NAME} :{" "}
                            {fluidPropertiesDropdownValues.gas.map(option => {
                              return defaultData.gasName === option.value
                                ? option.description
                                : null;
                            })}
                          </span>
                          <br />
                          <span
                            className={
                              calculateInput.flowrateGas <= 0 ||
                              inputErrors.flowrateGasErrorMessage !== ""
                                ? "errorValue"
                                : undefined
                            }
                          >
                            {" "}
                            {labelsData.SSA_FLOW_RATE} :{" "}
                            {this.roundSig(defaultData.flowrateGas)}{" "}
                            {defaultData.flowrateGasUom}{" "}
                          </span>
                          <br />

                          {/* ------ checking selected label is density or compressibility ------ */}
                          {defaultData.isDensityGas === "true" ? (
                            <span
                              className={
                                calculateInput.densityGas <= 0 ||
                                ((defaultData.densityGasError !== undefined &&
                                  defaultData.densityGasError !== "") ||
                                  inputErrors.densityGasErrorMessage !== "")
                                  ? "errorValue"
                                  : undefined
                              }
                            >
                              {labelsData.SSA_DENSITY} :{" "}
                              {defaultData.densityGasError !== undefined &&
                              defaultData.densityGasError !== "" ? (
                                this.props.labelsData[
                                  defaultData.densityGasError
                                ]
                              ) : (
                                <>
                                  {this.roundSig(defaultData.densityGas)}{" "}
                                  {defaultData.densityGasUom}
                                </>
                              )}
                            </span>
                          ) : (
                            <span
                              className={
                                (defaultData.compressibilityError !==
                                  undefined &&
                                  defaultData.compressibilityError !== "") ||
                                parseFloat(defaultData.compressibility) <= 0 ||
                                inputErrors.compressibilityErrorMessage !== ""
                                  ? "errorValue"
                                  : undefined
                              }
                            >
                              {labelsData.SSA_COMPRESSIBILITY_FACTOR} :{" "}
                              {defaultData.compressibilityError !== undefined &&
                              defaultData.compressibilityError !== ""
                                ? this.props.labelsData[
                                    defaultData.compressibilityError
                                  ]
                                : defaultData.compressibility}
                            </span>
                          )}
                          {/*-----------------------------------------------------------*/}

                          <br />
                          <span
                            className={
                              parseFloat(defaultData.molecularWeight) <= 0 ||
                              inputErrors.molecularWeightErrorMessage !== ""
                                ? "errorValue"
                                : undefined
                            }
                          >
                            {labelsData.SSA_MOLECULAR_WEIGHT} :{" "}
                            {this.roundSig(defaultData.molecularWeight)}{" "}
                          </span>
                        </div>
                      )}
                    </div>
                  </li>
                  {/* ---------------------- END OF FLUID PROPERTIES SECTION --------------------*/}

                  {/* ---------------------- RESULT SECTION -----------------------------*/}
                  <li
                    className={classnames(
                      {
                        "active-accordion": label === labelsData.SSA_RESULTS
                      },
                      {
                        containsError:
                          this.checkProcessError() === true ||
                          this.checkFluidPropertiesError() === true
                      },
                      { resultLi: true }
                    )}
                    onClick={() => {
                      if (
                        valveCapcityData.showLoader !== true &&
                        defaultData.showLoader !== true
                      ) {
                        this.changePage("results");
                      }
                    }}
                  >
                    {valveCapcityData.showLoader === true && (
                      <div className="d-flex justify-content-center loading resultloader"></div>
                    )}
                    <label>{labelsData.SSA_RESULTS}</label>

                    {this.checkProcessError() === true ||
                    this.checkFluidPropertiesError() === true ? (
                      <div>
                        <span>{labelsData.SSA_INPUT_ERROR} :</span>
                        <br />
                        <span class="errorValue">
                          {" "}
                          {labelsData.SSA_INPUT_ERROR_MSG}{" "}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span>
                          {labelsData.SSA_CALCULATED_CV} :{" "}
                          {valveCapcityData.calculateCV.error === "" ? (
                            <span>
                              {this.roundSig(
                                valveCapcityData.calculateCV
                                  .required_valve_capacity
                              )}{" "}
                            </span>
                          ) : (
                            <>
                              <span>
                                {labelsData[valveCapcityData.calculateCV.error]}
                              </span>
                            </>
                          )}
                        </span>
                        <br />
                        {/* ------------ Binding possible valve size in result accordion ---------- */}
                        <span>
                          {labelsData.SSA_POSSIBLE_VALVE_SIZES} : <br />
                          {valveCapcityData.valveSizeOpenings.error === "" ? (
                            valveCapcityData.valveSizeOpenings.valve_sizes_and_openings.map(
                              (valveData, index) => {
                                return (
                                  <>
                                    <span>
                                      {valveData[0]} {labelsData.SSA_INCH_VALVE}
                                      , {valveData[1]} {labelsData.SSA_OPEN}{" "}
                                    </span>
                                    <br />
                                  </>
                                );
                              }
                            )
                          ) : (
                            <>
                              <span>
                                {
                                  labelsData[
                                    valveCapcityData.valveSizeOpenings.error
                                  ]
                                }
                              </span>
                            </>
                          )}
                        </span>
                        {/*--------------------------------------------------------------*/}
                        <br />
                        {/* ------------ Checking result cavitation/noise message ---------- */}
                        <span>
                          {valveCapcityData.cavitationMessage !== null && (
                            <>
                              <span className="mt-4">
                                {labelsData.SSA_WARNING} :{" "}
                              </span>
                              <span>
                                {labelsData[valveCapcityData.cavitationMessage]}
                              </span>
                            </>
                          )}

                          {valveCapcityData.analyzeNoise !== null && (
                            <>
                              <span className="mt-4">
                                {labelsData.SSA_WARNING} :{" "}
                              </span>
                              <span>
                                {labelsData[valveCapcityData.analyzeNoise]}
                              </span>
                            </>
                          )}
                        </span>
                        {/*----------------------------------------------------------------*/}
                      </div>
                    )}
                  </li>
                  {/* ----------------------- END OF RESULT SECTION ---------------------*/}
                </ul>
              </div>
            </div>
          </div>

          <div class="font-style-custom global-footer">
            <div class="padded-container">
              <div class="row justify-content-sm-end">
                <div class="a-tags small col-sm-6">
                  <span className="displ-block">
                    <a href="https://www.emerson.com/en-us/terms-of-use">
                      Terms of Use
                    </a>
                  </span>
                  <span className="displ-block">
                    © Emerson Electric Co. All Rights Reserved
                  </span>
                  <span className="displ-block">Consider It Solved</span>
                </div>
                <div class="social col-sm-6">
                  <a
                    href="https://www.facebook.com/EmersonCorporate/"
                    aria-label=""
                    target="_blank"
                  >
                    <div class="image-container">
                      <img
                        class="cm-image"
                        src={facebook}
                        alt="Image of Footer Social Icon - Facebook"
                        title="Facebook"
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/emerson"
                    aria-label=""
                    target="_blank"
                  >
                    <div class="image-container">
                      <img
                        class="cm-image"
                        src={linkedin}
                        alt="Image of Footer Social Icon - LinkedIn"
                        title=""
                      />
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/Emerson_News"
                    aria-label=""
                    target="_blank"
                  >
                    <div class="image-container">
                      <img
                        class="cm-image"
                        src={twitter}
                        alt="Image of Footer Social Icon - Twitter"
                        title=""
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/user/emerson"
                    aria-label=""
                    target="_blank"
                  >
                    <div class="image-container">
                      <img
                        class="cm-image"
                        src={youtube}
                        alt="Image of Footer Social Icon - YouTube"
                        title=""
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.isShareResultModalOpen}>
          <ModalHeader>{labelsData.SSA_SHARE_RESULT}</ModalHeader>
          <ModalBody>
            {labelsData[mailSentDetails.mailSent] ===
            labelsData.SSA_MAIL_SENT ? (
              <div className="msgClass">
                {" "}
                {labelsData[mailSentDetails.mailSent]}{" "}
                {this.state.resultEmailID}
              </div>
            ) : (
              <>
                <label>{labelsData.SSA_EMAIL_ID}</label>
                <div className="row">
                  <div className="col-12">
                    <input
                      type="text"
                      placeholder={labelsData.SSA_EMAIL_ID}
                      name="emailID"
                      onChange={e => {
                        this.checkEmailValidation(e);
                        this.emailInputChange(e);
                      }}
                      value={this.state.resultEmailID}
                      className={
                        this.emailValid === false
                          ? "error-input form-control input-box mb-0"
                          : "form-control input-box mb-0"
                      }
                    />

                    <div class="error-tooltip">
                      {" "}
                      <span>{labelsData.SSA_EMAIL_ERR_MSG}</span>{" "}
                    </div>
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {labelsData[mailSentDetails.mailSent] ===
            labelsData.SSA_MAIL_SENT ? (
              <Button
                classname="btn btn-primary pull-right"
                text={labelsData.SSA_CLOSE}
                onclickevent={() => {
                  this.modalToggle();
                }}
              />
            ) : (
              <>
                <Button
                  classname="btn btn-primary pull-right"
                  text={labelsData.SSA_CLOSE}
                  onclickevent={() => {
                    this.modalToggle();
                  }}
                />

                <Button
                  classname="btn btn-primary pull-right sendMailBtn"
                  text={labelsData.SSA_SEND}
                  disabledButton={
                    !!(
                      this.emailValid === false ||
                      this.state.resultEmailID === ""
                    )
                  }
                  onclickevent={() => {
                    this.sendMail();
                  }}
                />
              </>
            )}
          </ModalFooter>
        </Modal>
      </ErrorBoundary>
    );
  }

  checkEmailValidation = (e: any) => {
    const emailRegEx = /\S+@\S+\.\S+/;
    this.emailValid = emailRegEx.test(e.target.value);
  };

  /* -- Function to capture physical details data on input change  -- */
  capturePhysicalDetails = (name: string, value: string) => {
    /* Assigning defaultData props value to local variable */
    const physicalDetails = Object.assign({}, this.props.defaultData);
    /* Setting changed value to respective object */
    physicalDetails[name] = value;

    /* -- calling validation if pipesize drop down changes -- */
    if (name === "pipeSize") {
      this.physicalDetailsDropdownValidation(
        value,
        physicalDetails,
        24,
        900,
        "pressureValue"
      );
    } else if (name === "pressureValue") {
      /* Calling validation if pressureValue drop down changes  */
      this.physicalDetailsDropdownValidation(
        value,
        physicalDetails,
        900,
        24,
        "pipeSize"
      );
    }

    /* updating changed object in defaultData store */
    this.props.changeDefaultData(physicalDetails);

    /* Calling result calculations on change in physical details drop down values */
    this.props.getRequiredValveCapacityFunction(
      physicalDetails,
      this.props.calculateInput
    );
  };

  /* -- Function to check pipe size and pressure dropdown validations based on value selection -- */
  physicalDetailsDropdownValidation = (
    value: string,
    physicalDetails: any,
    conditionCheckValue: number,
    valueDisableFrom: number,
    dropdownArrayKey: string
  ) => {
    /* Assigning defaultData props value to local variable */
    const physicalDetailsDropdownValues = Object.assign(
      {},
      this.props.physicalDetailsDropdownValues
    );
    /* Checking if changed value is greater than or equals to condition value */
    if (parseInt(value, 10) >= conditionCheckValue) {
      physicalDetailsDropdownValues[dropdownArrayKey].map(item => {
        const data =
          parseInt(item.value, 10) >= valueDisableFrom
            ? (item.isReadonly = true)
            : item;
        return data;
      });
    } else {
      physicalDetailsDropdownValues[dropdownArrayKey].map(item => {
        const data =
          parseInt(item.value, 10) >= valueDisableFrom
            ? (item.isReadonly = false)
            : item;
        return data;
      });
    }
    /* changePressureClassList event dispatch */
    this.props.changePressureClassList(
      physicalDetailsDropdownValues.pressureValue
    );
  };

  /* Function which calls on process input change and update the store  */
  processInputChange = (e: any) => {
    this.props.inputErrors[`${e.target.name}ErrorMessage`] = "";

    /* Regular expression for decimal and float value  */
    let ex = /^-?[0-9]+\.?[0-9]*$/;
    if (e.target.name === "noiseLimit") {
      ex = /^[0-9]+\.?[0-9]*$/;
    }

    /* Condition to check target value is empty, matching reg expression and */
    /* not equals to noiselimit */
    if (
      e.target.value === "" ||
      ex.test(e.target.value) === true ||
      (e.target.value === "-" && e.target.name !== "noiseLimit")
    ) {
      const process = Object.assign({}, this.props.defaultData);
      process[e.target.name] = e.target.value;
      this.props.changeDefaultData(process);
      this.inputChanged = true;
    }

    /* If field is empty setting error variable */
    if (e.target.value === "") {
      this.props.inputErrors[`${e.target.name}ErrorMessage`] =
        "SSA_WARN_EMPTY_FIELD";
    }
  };

  /* Function to change email input state */
  emailInputChange = (e: any) => {
    /* setting changed value to resultEmailID state variable */
    this.setState({
      resultEmailID: e.target.value
    });
  };

  /* -- Function which calls on process label change and performing calculation based on selected label -- */
  processLabelChange = (labelname: string) => {
    const process = Object.assign({}, this.props.defaultData);
    this.inputChanged = true;

    /* If changed label is equals to downstream pressure */
    /* then calculating downstream pressure value */
    if (labelname === this.props.labelsData.SSA_DOWN_PRESSURE) {
      process.isDownstreamP2 = "true";

      // changing downStreamPressureP2 (p1-pressuredrop)
      process.downStreamPressureP2 =
        parseFloat(this.props.calculateInput.upstreamPressureP1) -
        parseFloat(this.props.calculateInput.pressureChange);

      const dP = process.downStreamPressureP2;
      /* Checking downstream pressure value is number or not */
      if (Number.isNaN(dP) === true) {
        process.downStreamPressureP2 = "";
        this.props.calculateInput.downStreamPressureP2 = "";
        this.props.inputErrors.downStreamPressureP2ErrorMessage =
          "SSA_CALC_ERR_P1_PC"; // this.props.labelsData.SSA_CALC_ERR_P1_PC;
      } else {
        this.props.inputErrors.downStreamPressureP2ErrorMessage = "";
        process.downStreamPressureP2Uom = "psia";
        this.uomConversionFunction(
          "downStreamPressureP2",
          process.downStreamPressureP2,
          process.downStreamPressureP2Uom
        );
      }
    } else {
      /* If changed label is equals to upstream pressure */
      /* then calculating upstream pressure value */
      process.isDownstreamP2 = "false";

      // changing pressure drop value (p1-p2)
      process.pressureChange =
        parseFloat(this.props.calculateInput.upstreamPressureP1) -
        parseFloat(this.props.calculateInput.downStreamPressureP2);

      const pC = process.pressureChange;
      /* Checking upstream pressure value is number or not */
      if (Number.isNaN(pC) === true) {
        process.pressureChange = "";
        this.props.calculateInput.pressureChange = "";
        this.props.inputErrors.pressureChangeErrorMessage =
          "SSA_CALC_ERR_P1_P2"; // this.props.labelsData.SSA_CALC_ERR_P1_P2;
      } else {
        this.props.inputErrors.pressureChangeErrorMessage = "";
        this.uomConversionFunction(
          "pressureChange",
          process.pressureChange,
          this.props.defaultData.pressureChangeUom
        );
      }
    }

    /* changeDefaultData event is dispatching */
    /* to reset the default data values */
    this.props.changeDefaultData(process);
  };

  /* -- Function to clear error on input change in fluid properties page -- */
  clearError = (e: any) => {
    this.props.inputErrors[`${e.target.name}ErrorMessage`] = "";

    /* -- Clearing errors -- */
    if (
      e.target.name === "densityLiquid" ||
      e.target.name === "vaporPressurePV" ||
      e.target.name === "densityGas" ||
      e.target.name === "compressibility" ||
      e.target.name === "specificGravity"
    ) {
      const FPData = Object.assign({}, this.props.defaultData);
      FPData[e.target.name] = "";
      FPData[`${e.target.name}Error`] = "";
      /* changeDefaultData event is dispatching */
      /* to reset the default data values */
      this.props.changeDefaultData(FPData);
      this.props.inputErrors[`${e.target.name}ErrorMessage`] =
        "SSA_WARN_EMPTY_FIELD";
      this.inputChanged = true;
    }
  };

  /* -- Function which calls on any input change in fluid properties page -- */
  fluidPropertiesInputChange = (e: any) => {
    this.props.inputErrors[`${e.target.name}ErrorMessage`] = "";

    /* -- Regular expression to check decimal and float numbers -- */
    let ex = /^[0-9]+\.?[0-9]*$/;
    if (
      e.target.name === "criticalPressurePC" ||
      e.target.name === "vaporPressurePV"
    ) {
      ex = /^-?[0-9]+\.?[0-9]*$/;
    }
    if (
      e.target.value === "" ||
      ex.test(e.target.value) === true ||
      (e.target.value === "-" &&
        (e.target.name === "criticalPressurePC" ||
          e.target.name === "vaporPressurePV"))
    ) {
      const FPData = Object.assign({}, this.props.defaultData);
      FPData[e.target.name] = e.target.value;
      /* changeDefaultData event is dispatching */
      /* to reset the default data values */
      this.props.changeDefaultData(FPData);
      this.inputChanged = true;
    }

    if (e.target.value === "") {
      this.props.inputErrors[`${e.target.name}ErrorMessage`] =
        "SSA_WARN_EMPTY_FIELD";
    }
  };

  /* -- Function which calls on teb change in fluid properties page  -- */
  fluidPropertiesTabChange = (tabValue: string) => {
    const FPData = Object.assign({}, this.props.defaultData);
    FPData.selectedTab = tabValue;
    /* changeDefaultData event is dispatching */
    /* to reset the default data values */
    this.props.changeDefaultData(FPData);

    /* -- Calling result API -- */
    this.props.getFluidConstants(
      tabValue === "1" ? FPData.liquidName : FPData.gasName,
      FPData,
      this.props.calculateInput
    );
  };

  /* -- Function which calls on label change in fluid properties page -- */
  fluidPropertiesLabelChange = (tabName: string, labelName: string) => {
    const FPData = Object.assign({}, this.props.defaultData);
    const tabNameVar = tabName === "liquid" ? "isDensity" : "isDensityGas";
    FPData[tabNameVar] =
      labelName === this.props.labelsData.SSA_DENSITY ? "true" : "false";
    /* changeDefaultData event is dispatching */
    /* to reset the default data values */
    this.props.changeDefaultData(FPData);
  };

  /* -- Function which call on drop down changes in fluid properties page -- */
  captureFluidPropertiesDropdownValues = (name: string, value: string) => {
    const FPData = Object.assign({}, this.props.defaultData);

    /* Checking if gas name is steam to reset density label */
    if (value === "Steam") {
      FPData.isDensityGas = "true";
    }

    FPData[name] = value;
    /* changeDefaultData event is dispatching */
    /* to reset the default data values */
    this.props.changeDefaultData(FPData);

    this.props.getFluidConstants(value, FPData, this.props.calculateInput);
  };

  /* -- Function which calls on any uom drop down changes to perform uom conversion  -- */
  uomConversionFunction = (
    fieldName: string,
    fieldValue: any,
    uomValue: string
  ) => {
    if (this.inputChanged === true) {
      const calcualteInputData = Object.assign({}, this.props.calculateInput);

      if (fieldName === "flowrate" || fieldName === "flowrateGas") {
        this.props.getFlowRateUOMConversion(
          parseFloat(fieldValue),
          uomValue,
          "",
          fieldName,
          calcualteInputData
        );
      } else if (fieldName === "densityLiquid" || fieldName === "densityGas") {
        this.props.getDensityUOMConversion(
          parseFloat(fieldValue),
          uomValue,
          "lb/ft3",
          fieldName,
          calcualteInputData
        );
      } else if (
        fieldName === "vaporPressurePV" ||
        fieldName === "criticalPressurePC" ||
        fieldName === "upstreamPressureP1" ||
        fieldName === "downStreamPressureP2"
      ) {
        this.props.getPressureUOMConversion(
          parseFloat(fieldValue),
          uomValue,
          "psia",
          fieldName,
          calcualteInputData
        );
      } else if (fieldName === "temperatureT1") {
        this.props.getTemperatureUOMConversion(
          parseFloat(fieldValue),
          uomValue,
          "deg R",
          fieldName,
          calcualteInputData
        );
      } else if (fieldName === "pressureChange") {
        this.props.getPressureDropUOMConversion(
          parseFloat(fieldValue),
          uomValue,
          "psid",
          fieldName,
          calcualteInputData
        );
      } else if (fieldName === "specificGravity") {
        this.props.convertDensitySpecificGravity(
          parseFloat(this.props.defaultData.specificGravity),
          this.props.defaultData.densityLiquidUom,
          "specific_gravity",
          this.props.defaultData
        );
      } else if (
        fieldName === "compressibility" &&
        this.props.defaultData.gasName !== "Steam" &&
        this.props.defaultData.gasName !== "Other"
      ) {
        this.props.calculateGasDensityOnCompressibilityChange(
          this.props.defaultData.compressibility
        );
      } else if (
        fieldName === "molecularWeight" ||
        fieldName === "compressibility" ||
        fieldName === "noiselimit"
      ) {
        this.props.getRequiredValveCapacityFunction(
          this.props.defaultData,
          this.props.calculateInput
        );
      }
    }

    this.inputChanged = false;
  };

  onUOMChange = (name: string, uomValue: string) => {
    const defaultData = Object.assign({}, this.props.defaultData);
    defaultData[name] = uomValue;
    /* changeDefaultData event is dispatching */
    /* to reset the default data values */
    this.props.changeDefaultData(defaultData);
    this.inputChanged = true;
    const varName = name.split("Uom");
    this.uomConversionFunction(
      varName[0],
      this.props.defaultData[varName[0]],
      uomValue
    );
  };

  /* Share result email functionality */
  sendMail = () => {
    const defaultData = Object.assign({}, this.props.defaultData);
    /* Checking fluid type based on selected tab */
    defaultData.fluidType =
      this.props.defaultData.selectedTab === "1" ? "LIQUID" : "GAS";

    /* If calculated cv has error then we are sending error message else sending calculated data  */
    defaultData.calculated_cv =
      this.props.valveCapcityData.calculateCV.error === ""
        ? this.props.valveCapcityData.calculateCV.required_valve_capacity
        : this.props.labelsData[this.props.valveCapcityData.calculateCV.error];

    /* If valve size and openings has error then we are sending error message else sending calculated data  */
    defaultData.valve_sizes_and_openings =
      this.props.valveCapcityData.valveSizeOpenings.error === ""
        ? this.props.valveCapcityData.valveSizeOpenings.valve_sizes_and_openings
        : this.props.labelsData[
            this.props.valveCapcityData.valveSizeOpenings.error
          ];

    /* If cavitation msg is empty then we are sending null else sending message  */
    defaultData.cavitation_msg =
      this.props.valveCapcityData.cavitationMessage === null
        ? ""
        : this.props.labelsData[this.props.valveCapcityData.cavitationMessage];

    /* If noise msg is empty then we are sending null else sending message  */
    defaultData.noise_msg =
      this.props.valveCapcityData.analyzeNoise === null
        ? ""
        : this.props.labelsData[this.props.valveCapcityData.analyzeNoise];

    /* Checking for input errors if any then sending input error message else empty string  */
    defaultData.input_error =
      this.checkProcessError() === true ||
      this.checkFluidPropertiesError() === true
        ? this.props.labelsData.SSA_INPUT_ERROR_MSG
        : "";
    defaultData.language = this.state.lang;

    /* If any of the fields is having error in process/fluid properties making calculated cv and other fields to empty string */
    if (
      this.checkProcessError() === true ||
      this.checkFluidPropertiesError() === true
    ) {
      defaultData.calculated_cv = "";
      defaultData.valve_sizes_and_openings = "";
      defaultData.noise_msg = "";
    }

    /* --------------- Converting labels code to message -------------- */
    if (
      defaultData.densityLiquidError !== undefined &&
      defaultData.densityLiquidError !== ""
    ) {
      defaultData.densityLiquidError = this.props.labelsData[
        defaultData.densityLiquidError
      ];
    }

    if (
      defaultData.densityGasError !== undefined &&
      defaultData.densityGasError !== ""
    ) {
      defaultData.densityGasError = this.props.labelsData[
        defaultData.densityGasError
      ];
    }

    if (
      defaultData.vaporPressurePVError !== undefined &&
      defaultData.vaporPressurePVError !== ""
    ) {
      defaultData.vaporPressurePVError = this.props.labelsData[
        defaultData.vaporPressurePVError
      ];
    }

    if (
      defaultData.compressibilityError !== undefined &&
      defaultData.compressibilityError !== ""
    ) {
      defaultData.compressibilityError = this.props.labelsData[
        defaultData.compressibilityError
      ];
    }

    if (
      defaultData.specificGravityError !== undefined &&
      defaultData.specificGravityError !== ""
    ) {
      defaultData.specificGravityError = this.props.labelsData[
        defaultData.specificGravityError
      ];
    }
    /*------------------------------------------------------------------*/

    defaultData.cavitation_msg =
      defaultData.cavitation_msg === undefined
        ? ""
        : defaultData.cavitation_msg;

    const mailData = {
      email_id: this.state.resultEmailID,
      emailInput: defaultData
    };
    this.props.sendMail(mailData);
  };
}

/* -- Getting store data and storing in component props -- */
const mapStateToProps = store => {
  return {
    physicalDetailsDropdownValues: store.getPhysicalDetailsListValues,
    labelsData: store.getLabelsData,
    fluidPropertiesDropdownValues: store.getFluidPropertiesListValues,
    UOMListValues: store.getUOMList,
    defaultData: store.getDefaultValues,
    calculateInput: store.getCalcualteInputData,
    mailSentDetails: store.mailSentDetails,
    valveCapcityData: store.calculatedResult,
    inputErrors: store.inputErrors,
    languageList: store.languageList
  };
};

/* -- Dispatch events -- */
const mapDispatchToProps = dispatch => {
  return {
    loadPhysicalDetailsListValues: lang =>
      dispatch({
        type: "GET_PHYSICALDETAILS_LIST_VALUES_SUCCESS_ROOTSAGA",
        payload: { locale: lang }
      }),
    loadLabels: lang =>
      dispatch({ type: "GET_LABELS_DATA_SUCCESS_ROOTSAGA", lang }),
    loadFluidPropertiesListValues: lang =>
      dispatch({
        type: "GET_FLUIDPROPERTIES_LIST_SAGA",
        payload: { locale: lang }
      }),

    loadUOMLists: () => dispatch({ type: "GET_UOMLIST_SAGA" }),

    loadDefaultData: () => dispatch({ type: "GET_DEFAULT_DATA_SAGA" }),
    changeDefaultData: data =>
      dispatch({
        type: "GET_DEFAULT_DATA_SUCCESS",
        data
      }),

    changePressureClassList: pressureClassData =>
      dispatch({
        type: "GET_PRESSURECLASS_LIST_SUCCESS",
        pressureClassData
      }),

    getPressureUOMConversion: (
      value,
      oldUnit,
      newUnit,
      changedFieldName,
      calcualteInputData
    ) =>
      dispatch({
        type: "GET_PRESSURE_UOM_CONVERSION_DATA_SAGA",
        data: {
          value,
          old_unit: oldUnit,
          new_unit: newUnit,
          changedFieldName,
          calcualteInputData
        }
      }),

    getTemperatureUOMConversion: (
      value,
      oldUnit,
      newUnit,
      changedFieldName,
      calcualteInputData
    ) =>
      dispatch({
        type: "GET_TEMPERATURE_UOM_CONVERSION_DATA_SAGA",
        data: {
          value,
          old_unit: oldUnit,
          new_unit: newUnit,
          changedFieldName,
          calcualteInputData
        }
      }),

    getPressureDropUOMConversion: (
      value,
      oldUnit,
      newUnit,
      changedFieldName,
      calcualteInputData
    ) =>
      dispatch({
        type: "GET_PRESSURE_DROP_UOM_CONVERSION_DATA_SAGA",
        data: {
          value,
          old_unit: oldUnit,
          new_unit: newUnit,
          changedFieldName,
          calcualteInputData
        }
      }),

    getFlowRateUOMConversion: (
      value,
      oldUnit,
      newUnit,
      changedFieldName,
      calcualteInputData
    ) =>
      dispatch({
        type: "GET_FLOWRATE_UOM_CONVERSION_DATA_SAGA",
        data: {
          value,
          old_unit: oldUnit,
          new_unit: newUnit,
          changedFieldName,
          calcualteInputData
        }
      }),

    getDensityUOMConversion: (
      value,
      oldUnit,
      newUnit,
      changedFieldName,
      calcualteInputData
    ) =>
      dispatch({
        type: "GET_DENSITY_UOM_CONVERSION_DATA_SAGA",
        data: {
          value,
          old_unit: oldUnit,
          new_unit: newUnit,
          changedFieldName,
          calcualteInputData
        }
      }),

    getFluidConstants: (fluidName, defaultData, calcualteInputData) =>
      dispatch({
        type: "GET_FLUID_CONSTANTS_DATA_SAGA",
        data: {
          fluid_name: fluidName,
          default_data: defaultData,
          calcualteInputData
        }
      }),

    changeCalcualteInputData: data =>
      dispatch({
        type: "GET_CALCULATE_INPUT_DATA_SUCCESS",
        data
      }),

    sendMail: data =>
      dispatch({
        type: "SEND_MAIL_SAGA",
        data
      }),

    changeMailSentDetails: data =>
      dispatch({
        type: "SEND_MAIL_SUCCESS",
        data
      }),

    convertDensitySpecificGravity: (value, unit, gravDens, defaultData) =>
      dispatch({
        type: "GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY",
        data: {
          value,
          unit,
          grav_dens: gravDens,
          default_data: defaultData
        }
      }),

    getRequiredValveCapacityFunction: (defaultData, calculateInput) =>
      dispatch({
        type: "GET_RESULT_SAGA",
        data: {
          defaultData,
          calculateInput
        }
      }),

    updateInputErrors: data =>
      dispatch({
        type: "GET_INPUT_ERRORS_SUCCESS",
        data
      }),

    calculateGasDensityOnCompressibilityChange: data =>
      dispatch({
        type: "GET_CALCULATE_GAS_DENSITY_SAGA",
        data: {
          compressibility_factor: data
        }
      }),

    getLanguageList: () => {
      dispatch({
        type: "GET_LANGUAGE_LIST_SAGA"
      });
    }
  };
};

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
