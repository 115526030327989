/* -- File : utils/defaultData.js -- */
/* Author : SLK */
/* Description : which contains all default data */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
/* Labels default data */
export const labelsState = {
  SSA_PIPE_SIZE: "Pipe Size",
  SSA_APP_NAME: "Globe Valve Sizing Tool",
  SSA_LANGUAGE: "Language",
  SSA_PHYSICAL_DETAILS: "Physical Details",
  SSA_PRESSURE_CLASS: "Pressure Class",
  SSA_TRIM_TYPE: "Trim Type",
  SSA_UP_PRESSURE: "Upstream Pressure P1",
  SSA_DOWN_PRESSURE: "Downstream Pressure P2",
  SSA_PRESSURE_CHANGE: "Pressure Change",
  SSA_TEMPERATURE: "Temperature T1",
  SSA_PROCESS: "Process",
  SSA_NOISE_LIMIT: "Noise Limit",
  SSA_FLUID_PROPERTIES: "Fluid Properties",
  SSA_LIQUID: "Liquid",
  SSA_GAS: "Gas",
  SSA_LIQUID_NAME: "Liquid Name",
  SSA_GAS_NAME: "Gas Name",
  SSA_FLOW_RATE: "Flow Rate",
  SSA_DENSITY: "Density",
  SSA_SPECIFIC_GRAVITY: "Specific Gravity",
  SSA_VAPOR_PRESSURE: "Vapor Pressure",
  SSA_CRITICAL_PRESSURE: "Critical Pressure",
  SSA_MOLECULAR_WEIGHT: "Molecular Weight",
  SSA_NEXT: "Next",
  SSA_PREVIOUS: "Previous",
  SSA_RESULTS: "Results",
  SSA_CALCULATED_CV: "Calculated Cv",
  SSA_POSSIBLE_VALVE_SIZES: "Possible Valve Sizes",
  SSA_INCH_VALVE: "inch valve",
  SSA_OPEN: "open",
  showLoader: false
};

/* Uom list default data */
export const UOMListValues = {
  density: [
    { value: "psig", description: "psig" },
    { value: "psia", description: "psia" }
  ],
  pressure: [
    { value: "lb/ft3", description: "lb/ft3" },
    { value: "kg/m3", description: "kg/m3" }
  ],
  flowrateLiquid: [
    { value: "gpm", description: "gpm" },
    { value: "lb/s", description: "lb/s" }
  ],
  flowrateGas: [
    { value: "gpm", description: "gpm" },
    { value: "lb/s", description: "lb/s" }
  ],
  temperature: [
    { value: "F", description: "F" },
    { value: "C", description: "C" }
  ],
  noiseLimit: [
    { value: "F", description: "F" },
    { value: "C", description: "C" }
  ],
  pressureDrop: [
    { value: "F", description: "F" },
    { value: "C", description: "C" }
  ],
  showLoader: false
};

/* Default values data */
export const defaultValues = {
  "Fluid Type": "Liquid",
  specificGravity: "1.0",
  compressibility: "1.0",
  noiseLimit: "85",
  temperatureT1: "100",
  pipeSize: "6",
  trimType: "Equal Percent",
  liquidName: "Water",
  flowrate: "1000",
  densityLiquid: "62.418",
  densityGas: "0.2",
  molecularWeight: "18.01",
  criticalPressurePC: "3200",
  vaporPressurePV: "0.95",
  gasName: "Steam",
  pressureValue: "300",
  isDownstreamP2: "true",
  upstreamPressureP1: "100",
  downStreamPressureP2: "10",
  pressureChange: "90",
  selectedTab: "1",
  isDensity: "true",
  isDensityGas: "true",
  flowrateGas: "1000",
  noiseLimitUom: "dBA",
  temperatureT1Uom: "deg F",
  flowrateUom: "lb/m",
  densityLiquidUom: "lb/ft3",
  densityGasUom: "lb/ft3",
  criticalPressurePCUom: "psia",
  vaporPressurePVUom: "psia",
  upstreamPressureP1Uom: "psia",
  downStreamPressureP2Uom: "psia",
  pressureChangeUom: "psid",
  flowrateGasUom: "lb/m",
  showLoader: false
};

/* calculated input default data */
export const calculateInputData = {
  "Fluid Type": "Liquid",
  specificGravity: "1.0",
  compressibility: "1.0",
  noiseLimit: "85",
  temperatureT1: "100",
  pipeSize: "6",
  trimType: "Equal Percent",
  liquidName: "Water",
  flowrate: "1000",
  densityLiquid: "62.418",
  densityGas: "0.2",
  molecularWeight: "18.01",
  criticalPressurePC: "3200",
  vaporPressurePV: "0.95",
  gasName: "Steam",
  pressureValue: "300",
  isDownstreamP2: "true",
  upstreamPressureP1: "100",
  downStreamPressureP2: "10",
  pressureChange: "90",
  selectedTab: "1",
  isDensity: "true",
  isDensityGas: "true",
  flowrateGas: "1000",
  flowrateBaseUnit: "lb/m"
};

/* input errors default data */
export const defaultInputErrors = {
  upstreamPressureP1ErrorMessage: "",
  downStreamPressureP2ErrorMessage: "",
  pressureChangeErrorMessage: "",
  temperatureT1ErrorMessage: "",
  noiseLimitErrorMessage: "",
  flowrateErrorMessage: "",
  densityLiquidErrorMessage: "",
  specificGravityErrorMessage: "",
  vaporPressurePVErrorMessage: "",
  criticalPressurePCErrorMessage: "",
  flowrateGasErrorMessage: "",
  densityGasErrorMessage: "",
  compressibilityErrorMessage: "",
  molecularWeightErrorMessage: ""
};

/* Result default data */
export const defaultResultValue = {
  showLoader: false,
  calculateCV: { error: "", required_valve_capacity: "13.229722740266986" },
  cavitationMessage: null,
  analyzeNoise: null,
  valveSizeOpenings: {
    error: "",
    valve_sizes_and_openings: [[6.0, "< 10%"], [4.0, "< 10%"], [3.0, "20%"]]
  }
};
