/* -- File : rootSaga.js -- */
/* Author : SLK */
/* Description : main reducer file which combines all the reducers */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import { takeLatest } from "redux-saga/effects";
import { fetchPhysicalDetailsListValues } from "./PhysicalDetails/saga";
import * as PHYSICAL_DETAILS_CONSTANTS from "./PhysicalDetails/constant";

import {
  fetchLabels,
  fetchUomList,
  fetchDefaultValues,
  fetchPressureUOMConversion,
  fetchPressureDropUOMConversion,
  fetchTemperatureUOMConversion,
  fetchFlowRateUOMConversion,
  fetchDensityUOMConversion,
  fetchFluidConstants,
  sendMailSaga,
  fetchConvertedDensitySpecificGravity,
  fetchRequiredValveCapacity,
  calculateGasDensity,
  getLanguageListSaga
} from "./common/saga";
import * as COMMON_CONSTANTS from "./common/constant";

import { fetchFluidPropertiesListValues } from "./FluidProperties/saga";
import * as FP_CONSTANTS from "./FluidProperties/constant";

/* Main saga function (root saga) */
function* rootSaga() {
  yield takeLatest(
    PHYSICAL_DETAILS_CONSTANTS.GET_PHYSICALDETAILS_LIST_VALUES_SUCCESS_ROOTSAGA,
    fetchPhysicalDetailsListValues
  );
  yield takeLatest(
    COMMON_CONSTANTS.GET_LABELS_DATA_SUCCESS_ROOTSAGA,
    fetchLabels
  );

  yield takeLatest(
    FP_CONSTANTS.GET_FLUIDPROPERTIES_LIST_SAGA,
    fetchFluidPropertiesListValues
  );

  yield takeLatest(COMMON_CONSTANTS.GET_UOMLIST_SAGA, fetchUomList);

  yield takeLatest(COMMON_CONSTANTS.GET_DEFAULT_DATA_SAGA, fetchDefaultValues);
  yield takeLatest(
    COMMON_CONSTANTS.GET_PRESSURE_UOM_CONVERSION_DATA_SAGA,
    fetchPressureUOMConversion
  );
  yield takeLatest(
    COMMON_CONSTANTS.GET_PRESSURE_DROP_UOM_CONVERSION_DATA_SAGA,
    fetchPressureDropUOMConversion
  );
  yield takeLatest(
    COMMON_CONSTANTS.GET_TEMPERATURE_UOM_CONVERSION_DATA_SAGA,
    fetchTemperatureUOMConversion
  );
  yield takeLatest(
    COMMON_CONSTANTS.GET_FLOWRATE_UOM_CONVERSION_DATA_SAGA,
    fetchFlowRateUOMConversion
  );
  yield takeLatest(
    COMMON_CONSTANTS.GET_DENSITY_UOM_CONVERSION_DATA_SAGA,
    fetchDensityUOMConversion
  );

  yield takeLatest(
    COMMON_CONSTANTS.GET_FLUID_CONSTANTS_DATA_SAGA,
    fetchFluidConstants
  );

  yield takeLatest(COMMON_CONSTANTS.SEND_MAIL_SAGA, sendMailSaga);

  yield takeLatest(
    COMMON_CONSTANTS.GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY,
    fetchConvertedDensitySpecificGravity
  );

  yield takeLatest(
    COMMON_CONSTANTS.GET_RESULT_SAGA,
    fetchRequiredValveCapacity
  );

  yield takeLatest(
    COMMON_CONSTANTS.GET_CALCULATE_GAS_DENSITY_SAGA,
    calculateGasDensity
  );

  yield takeLatest(
    COMMON_CONSTANTS.GET_LANGUAGE_LIST_SAGA,
    getLanguageListSaga
  );
}

export default rootSaga;
