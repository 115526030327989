/* -- File : common/api.js */
/* Author : SLK */
/* Description : API file which contains API calls */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import axios from "axios";
import { API_URL } from "../../utils/constant";

/* Axios instance creation */
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  },
  timeout: 240000
});

/* API function to get labels data */
export const fetchLabelsAPI = lang => {
  return axiosInstance
    .post("appDataView/", {
      locale: lang
    })
    .then(response => response.data);
};

/* API function to get UOM list */
export const fetchUomListAPI = () => {
  return axiosInstance
    .get("unitsOfMeasurementView/")
    .then(response => response.data);
};

/* API function to get default values */
export const fetchDefaultValuesAPI = () => {
  return axiosInstance
    .get("defaultValuesView/")
    .then(response => response.data);
};

/* API function to convert pressure UOM */
export const fetchPressureUOMConversionAPI = data => {
  return axiosInstance
    .post("pressureConversion/", data)
    .then(response => response.data);
};

/* API function to convert pressure drop UOM */
export const fetchPressureDropUOMConversionAPI = data => {
  return axiosInstance
    .post("pressureDropConversion/", data)
    .then(response => response.data);
};

/* API function to convert temperature UOM */
export const fetchTemperatureUOMConversionAPI = data => {
  return axiosInstance
    .post("temperatureConversion/", data)
    .then(response => response.data);
};

/* API function to convert flow rate UOM */
export const fetchFlowRateUOMConversionAPI = data => {
  return axiosInstance
    .post("flowRateConversion/", data)
    .then(response => response.data);
};

/* API function to convert density UOM */
export const fetchDensityUOMConversionAPI = data => {
  return axiosInstance
    .post("denistyConversion/", data)
    .then(response => response.data);
};

/* API function to get fluid constant data */
export const fetchFluidConstantsAPI = data => {
  return axiosInstance
    .post("fluidConstantsFluidProp/", data)
    .then(response => response.data);
};

/* API function to send mail */
export const sendMailSagaAPI = data => {
  return axiosInstance
    .post("shareEmailResult/", data)
    .then(response => response.data);
};

/* API function to convert density to specific gravity and vice versa */
export const fetchConvertedDensitySpecificGravityAPI = data => {
  return axiosInstance
    .post("specificGravityDensityConversion/", data)
    .then(response => response.data);
};

/* API function to fetch calculated Cv */
export const fetchRequiredValveCapacityAPI = data => {
  return axiosInstance
    .post("requiredValveCapacityResults/", data)
    .then(response => response.data);
};

/* API function to fetch calculated Cv */
export const analyzeCavitationAPI = data => {
  return axiosInstance
    .post("analyzeCavitationResults/", data)
    .then(response => response.data);
};

/* API function to get density value when fluid is water */
export const waterSteamLiquidDensityApi = data => {
  return axiosInstance
    .post("liquidDensityWaterSteamProp/", data)
    .then(response => response.data);
};

/* API function to get vapor pressure value when fluid is water */
export const waterSteamVaporPressureApi = data => {
  return axiosInstance
    .post("vapourPressureWaterSteamProp/", data)
    .then(response => response.data);
};

/* API function to get density value when fluid is not water,typical crude oil and other */
export const fluidPropertiesLiquidDensityApi = data => {
  return axiosInstance
    .post("liquidDensityFluidProp/", data)
    .then(response => response.data);
};

/* API function to get vapor pressure value when fluid is not water,typical crude oil and other */
export const fluidPropertiesVaporPressureApi = data => {
  return axiosInstance
    .post("vapourPressureFluidProp/", data)
    .then(response => response.data);
};

/* API function to get density value when fluid is steam */
export const waterSteamDensityApi = data => {
  return axiosInstance
    .post("steamDensityWaterSteamProp/", data)
    .then(response => response.data);
};

/* API function to get compressibility value */
export const fluidPropertiesCompressibilityApi = data => {
  return axiosInstance
    .post("compressibilityFactorFluidProp/", data)
    .then(response => response.data);
};

/* API function to get gas density value */
export const fluidPropertiesGasDensityApi = data => {
  return axiosInstance
    .post("gasDensityFluidProp/", data)
    .then(response => response.data);
};

/* API function to fetch ValveData */
export const fetchValveDataAPI = data => {
  return axiosInstance
    .post("retrieveValveDataResults/", data)
    .then(response => response.data);
};

/* API function to fetch ValveData */
export const fetchvalveSizesOpeningsAPI = data => {
  return axiosInstance
    .post("valveSizesOpeningsResults/", data)
    .then(response => response.data);
};

/* API function to fetch ValveData */
export const fetchAnalyzeNoiseAPI = data => {
  return axiosInstance
    .post("analyzeNoiseResults/", data)
    .then(response => response.data);
};

/* API function to language list */
export const getLanguageListAPI = () => {
  return axiosInstance.get("languagesView/").then(response => response.data);
};
