// @flow
/* -- File : FluidProperties.js */
/* Author : SLK */
/* Description : Fluid properties container which contains fluid properties UI and functions */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import NumericLabel from "react-pretty-numbers";
import Dropdown from "../../components/Dropdown/Dropdown";
import LabelDropdown from "../../components/LabelDropdown/LabelDropdown";
import { ErrorBoundary } from "../../components/errorBoundary";

/* -- component props type -- */
type FluidPropertiesProps = {
  data: any,
  onChangeInput: any,
  onUomChange: any,
  labels: any,
  labelChange: any,
  dropdownValues: any,
  onChange: any,
  UOMListValues: any,
  tabChange: any,
  calculateInput: any,
  onBlurInput: any,
  inputErrors: any,
  clearError: any
};

/* -- component state type -- */
type FluidPropertiesState = {
  activeTab: string
};

/* Fluid properties component class */
export class FluidProperties extends Component<
  FluidPropertiesProps,
  FluidPropertiesState
> {
  /* Render method */
  /* Which Return the html code of the component */
  render() {
    const {
      labels,
      dropdownValues,
      UOMListValues,
      calculateInput,
      onBlurInput,
      inputErrors,
      clearError
    } = this.props;
    const numberOption = {
      precision: 2,
      commafy: false,
      cssClass: ["numericFormatClass"]
    };
    return (
      <ErrorBoundary>
        <Nav tabs data-test="fluidPropertiesTest">
          {/* -- Liquid Tab Button -- */}
          <NavItem className="cursor-pointer navlink1">
            <NavLink
              className={classnames({
                active: this.props.data.selectedTab === "1"
              })}
              onClick={() => {
                this.props.tabChange("1");
              }}
            >
              {labels.SSA_LIQUID}
            </NavLink>
          </NavItem>
          {/* -- Gas Tab Button -- */}
          <NavItem className="cursor-pointer navlink2">
            <NavLink
              className={classnames({
                active: this.props.data.selectedTab === "2"
              })}
              onClick={() => {
                this.props.tabChange("2");
              }}
            >
              {labels.SSA_GAS}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.props.data.selectedTab}>
          {/* ---------- LIQUID TAB --------------*/}
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                {/* ---------- liquid name --------------*/}
                <label> {labels.SSA_LIQUID_NAME} </label>
                <Dropdown
                  options={dropdownValues.liquid}
                  classes={"text-uppercase"}
                  value={this.props.data.liquidName}
                  changedStateValue={state =>
                    this.props.onChange("liquidName", state)
                  }
                  name="liquidName"
                />

                {/* ---------- liquid flow rate --------------*/}
                <label> {labels.SSA_FLOW_RATE} </label>

                <div className="row">
                  <div className="col-sm-9 col-6">
                    <input
                      type="text"
                      className="form-control input-box"
                      placeholder={labels.SSA_FLOW_RATE}
                      name="flowrate"
                      onChange={e => {
                        this.props.onChangeInput(e);
                      }}
                      value={this.props.data.flowrate}
                      onBlur={e => {
                        onBlurInput(e);
                      }}
                      className={
                        calculateInput.flowrate <= 0 ||
                        inputErrors.flowrateErrorMessage !== ""
                          ? "error-input form-control input-box"
                          : "form-control input-box"
                      }
                    />
                    {/* ---------- liquid flow rate error tooltip --------------*/}
                    <div className="error-tooltip">
                      {" "}
                      {inputErrors.flowrateErrorMessage !== "" ? (
                        <span>{labels[inputErrors.flowrateErrorMessage]} </span>
                      ) : (
                        <span>{labels.SSA_WARN_FLOW_RATE_GT_0} </span>
                      )}{" "}
                    </div>
                  </div>
                  {/* ---------- liquid flow rate UOM dropdown --------------*/}
                  <div className="col-sm-3 col-6">
                    <Dropdown
                      name="flowrateUom"
                      options={UOMListValues.flowrateLiquid}
                      value={this.props.data.flowrateUom}
                      changedStateValue={uomObj =>
                        this.props.onUomChange("flowrateUom", uomObj)
                      }
                      classes="dropdown-tooltip"
                    />
                    {/* ---------- liquid flow rate UOM dropdown tooltip --------------*/}
                    <div className="dropdown-tooltip-body">
                      {" "}
                      <span>
                        <NumericLabel params={numberOption}>
                          {calculateInput.flowrate}
                        </NumericLabel>
                        &nbsp;
                        {calculateInput.flowrateBaseUnit}
                      </span>{" "}
                    </div>
                  </div>
                </div>

                {/* ---------- density liquid or specific gravity label dropdown --------------*/}

                <LabelDropdown
                  className="labeldropdown1"
                  labelname={
                    this.props.data.isDensity === "true"
                      ? labels.SSA_DENSITY
                      : labels.SSA_SPECIFIC_GRAVITY
                  }
                  labelnameList={[
                    labels.SSA_DENSITY,
                    labels.SSA_SPECIFIC_GRAVITY
                  ]}
                  labelChange={labelname =>
                    this.props.labelChange("liquid", labelname)
                  }
                />

                {/* ---------- checking density liquid or specific gravity --------------*/}
                {this.props.data.isDensity === "true" ? (
                  <div className="row">
                    {/* ---------- Density liquid --------------*/}
                    <div className="col-sm-9 col-6">
                      {/* Checking for temperature errors in density liquid */}
                      {this.props.data.densityLiquidError !== undefined &&
                      this.props.data.densityLiquidError !== "" ? (
                        <>
                          <input
                            type="text"
                            placeholder={labels.SSA_DENSITY}
                            name="densityLiquid"
                            value={labels[this.props.data.densityLiquidError]}
                            className={"error-input form-control input-box"}
                            onChange={e => {
                              clearError(e);
                            }}
                          />
                          {/* ---------- Density liquid error tooltip --------------*/}
                          <div className="error-tooltip">
                            {" "}
                            <span>
                              {labels[this.props.data.densityLiquidError]}
                            </span>{" "}
                          </div>
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            placeholder={labels.SSA_DENSITY}
                            name="densityLiquid"
                            onChange={e => {
                              this.props.onChangeInput(e);
                            }}
                            onBlur={e => {
                              onBlurInput(e);
                            }}
                            value={this.props.data.densityLiquid}
                            className={
                              calculateInput.densityLiquid <= 0 ||
                              inputErrors.densityLiquidErrorMessage !== ""
                                ? "error-input form-control input-box"
                                : "form-control input-box"
                            }
                          />
                          {/* ---------- Density liquid error tooltip --------------*/}
                          <div className="error-tooltip">
                            {" "}
                            {inputErrors.densityLiquidErrorMessage !== "" ? (
                              <span>
                                {labels[inputErrors.densityLiquidErrorMessage]}{" "}
                              </span>
                            ) : (
                              <span>{labels.SSA_WARN_DENSITY_GT_0} </span>
                            )}{" "}
                          </div>
                        </>
                      )}
                    </div>
                    {/* ---------- checking whether density is selected or not to show UOM drop down --------------*/}
                    {this.props.data.isDensity === "true" ? (
                      <div className="col-sm-3 col-6">
                        <Dropdown
                          name="densityLiquidUom"
                          options={UOMListValues.density}
                          value={this.props.data.densityLiquidUom}
                          changedStateValue={uomObj =>
                            this.props.onUomChange("densityLiquidUom", uomObj)
                          }
                          classes="dropdown-tooltip"
                        />

                        <div className="dropdown-tooltip-body">
                          {" "}
                          <span>
                            <NumericLabel params={numberOption}>
                              {calculateInput.densityLiquid}
                            </NumericLabel>
                            &nbsp; lb/ft3
                          </span>{" "}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="row">
                    {/* ---------- Specific Gravity --------------*/}

                    {this.props.data.specificGravityError !== undefined &&
                    this.props.data.specificGravityError !== "" ? (
                      <div className="col-12">
                        <input
                          type="text"
                          className="form-control input-box"
                          placeholder={labels.SSA_SPECIFIC_GRAVITY}
                          name="specificGravity"
                          onChange={e => {
                            clearError(e);
                          }}
                          onBlur={e => {
                            onBlurInput(e);
                          }}
                          value={labels[this.props.data.specificGravityError]}
                          className={"error-input form-control input-box"}
                        />
                        {/* ---------- Specific Gravity error tooltip --------------*/}
                        <div className="error-tooltip">
                          <span>
                            {labels[this.props.data.specificGravityError]}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12">
                        <input
                          type="text"
                          className="form-control input-box"
                          placeholder={labels.SSA_SPECIFIC_GRAVITY}
                          name="specificGravity"
                          onChange={e => {
                            this.props.onChangeInput(e);
                          }}
                          onBlur={e => {
                            onBlurInput(e);
                          }}
                          value={this.props.data.specificGravity}
                          className={
                            parseFloat(this.props.data.specificGravity) <= 0 ||
                            inputErrors.specificGravityErrorMessage !== ""
                              ? "error-input form-control input-box"
                              : "form-control input-box"
                          }
                        />

                        <div className="error-tooltip">
                          {" "}
                          {inputErrors.specificGravityErrorMessage !== "" ? (
                            <span>
                              {labels[inputErrors.specificGravityErrorMessage]}
                            </span>
                          ) : (
                            <span>{labels.SSA_WARN_SPECIFIC_GRAVITY_GT_0}</span>
                          )}{" "}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* ---------- vapor pressure --------------*/}
                <label>
                  {" "}
                  {labels.SSA_VAPOR_PRESSURE}{" "}
                  <span className="text-capitalize"> Pv </span>{" "}
                </label>

                <div className="row">
                  <div className="col-sm-9 col-6">
                    {/* Checking for temperature errors in vapor pressure */}
                    {this.props.data.vaporPressurePVError !== undefined &&
                    this.props.data.vaporPressurePVError !== "" ? (
                      <>
                        <input
                          type="text"
                          className="form-control input-box"
                          placeholder={labels.SSA_VAPOR_PRESSURE}
                          name="vaporPressurePV"
                          onChange={e => {
                            clearError(e);
                          }}
                          onBlur={e => {
                            onBlurInput(e);
                          }}
                          value={labels[this.props.data.vaporPressurePVError]}
                          className={"error-input form-control input-box"}
                        />
                        {/* ---------- vapor pressure error tooltip --------------*/}
                        <div className="error-tooltip">
                          {" "}
                          <span>
                            {labels[this.props.data.vaporPressurePVError]}
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          className="form-control input-box"
                          placeholder={labels.SSA_VAPOR_PRESSURE}
                          name="vaporPressurePV"
                          onChange={e => {
                            this.props.onChangeInput(e);
                          }}
                          onBlur={e => {
                            onBlurInput(e);
                          }}
                          value={this.props.data.vaporPressurePV}
                          className={
                            calculateInput.vaporPressurePV <= 0 ||
                            inputErrors.vaporPressurePVErrorMessage !== ""
                              ? "error-input form-control input-box"
                              : "form-control input-box"
                          }
                        />

                        <div className="error-tooltip">
                          {" "}
                          {inputErrors.vaporPressurePVErrorMessage !== "" ? (
                            <span>
                              {labels[inputErrors.vaporPressurePVErrorMessage]}
                            </span>
                          ) : (
                            <span>{labels.SSA_WARN_PRES_GT_0}</span>
                          )}{" "}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-sm-3 col-6">
                    <Dropdown
                      name="vaporPressurePVUom"
                      options={UOMListValues.pressure}
                      value={this.props.data.vaporPressurePVUom}
                      changedStateValue={uomObj =>
                        this.props.onUomChange("vaporPressurePVUom", uomObj)
                      }
                      classes="dropdown-tooltip"
                    />

                    <div className="dropdown-tooltip-body">
                      {" "}
                      <span>
                        <NumericLabel params={numberOption}>
                          {calculateInput.vaporPressurePV}
                        </NumericLabel>
                        &nbsp; psia
                      </span>{" "}
                    </div>
                  </div>
                </div>

                {/* ---------- critical pressure --------------*/}

                <label>
                  {" "}
                  {labels.SSA_CRITICAL_PRESSURE}{" "}
                  <span className="text-capitalize"> Pc </span>{" "}
                </label>

                <div className="row">
                  <div className="col-sm-9 col-6">
                    <input
                      type="text"
                      className="form-control input-box"
                      placeholder={labels.SSA_CRITICAL_PRESSURE}
                      name="criticalPressurePC"
                      onChange={e => {
                        this.props.onChangeInput(e);
                      }}
                      onBlur={e => {
                        onBlurInput(e);
                      }}
                      value={this.props.data.criticalPressurePC}
                      className={
                        calculateInput.criticalPressurePC <= 0 ||
                        inputErrors.criticalPressurePCErrorMessage !== ""
                          ? "error-input form-control input-box"
                          : "form-control input-box"
                      }
                    />
                    {/* ---------- critical pressure error tooltip --------------*/}
                    <div className="error-tooltip">
                      {" "}
                      {inputErrors.criticalPressurePCErrorMessage !== "" ? (
                        <span>
                          {labels[inputErrors.criticalPressurePCErrorMessage]}
                        </span>
                      ) : (
                        <span>{labels.SSA_WARN_PRES_GT_0} </span>
                      )}{" "}
                    </div>
                  </div>
                  {/* ---------- critical pressure Uom drop down --------------*/}
                  <div className="col-sm-3 col-6">
                    <Dropdown
                      name="criticalPressurePCUom"
                      options={UOMListValues.pressure}
                      value={this.props.data.criticalPressurePCUom}
                      changedStateValue={uomObj =>
                        this.props.onUomChange("criticalPressurePCUom", uomObj)
                      }
                      classes="dropdown-tooltip"
                    />

                    <div className="dropdown-tooltip-body">
                      {" "}
                      <span>
                        <NumericLabel params={numberOption}>
                          {calculateInput.criticalPressurePC}
                        </NumericLabel>
                        &nbsp; psia
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane>

          {/* --------------------------------------- GAS TAB -------------------------------------*/}
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                {/* ---------- gas name --------------*/}
                <label> {labels.SSA_GAS_NAME} </label>
                <Dropdown
                  options={dropdownValues.gas}
                  classes={"text-uppercase"}
                  value={this.props.data.gasName}
                  changedStateValue={state =>
                    this.props.onChange("gasName", state)
                  }
                  name="gasName"
                />

                {/* ----------gas flow rate --------------*/}
                <label> {labels.SSA_FLOW_RATE} </label>

                <div className="row">
                  <div className="col-sm-9 col-6">
                    <input
                      type="text"
                      className="form-control input-box"
                      placeholder={labels.SSA_FLOW_RATE}
                      name="flowrateGas"
                      onChange={e => {
                        this.props.onChangeInput(e);
                      }}
                      onBlur={e => {
                        onBlurInput(e);
                      }}
                      value={this.props.data.flowrateGas}
                      className={
                        calculateInput.flowrateGas <= 0 ||
                        inputErrors.flowrateGasErrorMessage !== ""
                          ? "error-input form-control input-box"
                          : "form-control input-box"
                      }
                    />
                    {/* ----------gas flow rate error tooltip --------------*/}
                    <div className="error-tooltip">
                      {" "}
                      {inputErrors.flowrateGasErrorMessage !== "" ? (
                        <span>
                          {labels[inputErrors.flowrateGasErrorMessage]}
                        </span>
                      ) : (
                        <span>{labels.SSA_WARN_FLOW_RATE_GT_0} </span>
                      )}{" "}
                    </div>
                  </div>
                  {/* ----------gas flow rate Uom Drop down --------------*/}
                  <div className="col-sm-3 col-6">
                    <Dropdown
                      name="flowrateGasUom"
                      options={UOMListValues.flowrateGas}
                      value={this.props.data.flowrateGasUom}
                      changedStateValue={uomObj =>
                        this.props.onUomChange("flowrateGasUom", uomObj)
                      }
                      classes="dropdown-tooltip"
                    />
                    {/* ----------gas flow rate Uom drop down tooltip --------------*/}
                    <div className="dropdown-tooltip-body">
                      {" "}
                      <span>
                        <NumericLabel params={numberOption}>
                          {calculateInput.flowrateGas}
                        </NumericLabel>
                        &nbsp;
                        {calculateInput.flowrateBaseUnit}
                      </span>{" "}
                    </div>
                  </div>
                </div>

                {/* ---------- Density and compressibilty factor dropdown--------------*/}

                {this.props.data.gasName === "Steam" ? (
                  <label> {labels.SSA_DENSITY} </label>
                ) : (
                  <LabelDropdown
                    labelname={
                      this.props.data.isDensityGas === "true"
                        ? labels.SSA_DENSITY
                        : labels.SSA_COMPRESSIBILITY_FACTOR
                    }
                    labelnameList={[
                      labels.SSA_DENSITY,
                      labels.SSA_COMPRESSIBILITY_FACTOR
                    ]}
                    labelChange={labelname =>
                      this.props.labelChange("gas", labelname)
                    }
                  />
                )}

                {/* ---------- checking for density or compressibity --------------*/}
                {this.props.data.isDensityGas === "true" ? (
                  <div className="row">
                    {/* ---------- Density Gas ---------- */}

                    {this.props.data.densityGasError !== undefined &&
                    this.props.data.densityGasError !== "" ? (
                      <div className="col-sm-9 col-6">
                        <input
                          type="text"
                          placeholder={labels.SSA_DENSITY}
                          name="densityGas"
                          onChange={e => {
                            clearError(e);
                          }}
                          onBlur={e => {
                            onBlurInput(e);
                          }}
                          value={labels[this.props.data.densityGasError]}
                          className="error-input form-control input-box"
                        />
                        {/* ---------- Density Gas error tooltip ---------- */}
                        <div className="error-tooltip">
                          <span>{labels[this.props.data.densityGasError]}</span>
                        </div>
                      </div>
                    ) : (
                      <div className="col-sm-9 col-6">
                        <input
                          type="text"
                          placeholder={labels.SSA_DENSITY}
                          name="densityGas"
                          onChange={e => {
                            this.props.onChangeInput(e);
                          }}
                          onBlur={e => {
                            onBlurInput(e);
                          }}
                          value={this.props.data.densityGas}
                          className={
                            calculateInput.densityGas <= 0 ||
                            inputErrors.densityGasErrorMessage !== ""
                              ? "error-input form-control input-box"
                              : "form-control input-box"
                          }
                        />

                        <div className="error-tooltip">
                          {" "}
                          {inputErrors.densityGasErrorMessage !== "" ? (
                            <span>
                              {labels[inputErrors.densityGasErrorMessage]}{" "}
                            </span>
                          ) : (
                            <span>{labels.SSA_WARN_DENSITY_GT_0} </span>
                          )}{" "}
                        </div>
                      </div>
                    )}
                    {/* ---------- Density Gas Uom Drop down---------- */}
                    <div className="col-sm-3 col-6">
                      <Dropdown
                        name="densityGasUom"
                        options={UOMListValues.density}
                        value={this.props.data.densityGasUom}
                        changedStateValue={uomObj =>
                          this.props.onUomChange("densityGasUom", uomObj)
                        }
                        classes="dropdown-tooltip"
                      />
                      {/* ---------- Density Gas Uom Drop down tool tip---------- */}
                      <div className="dropdown-tooltip-body">
                        {" "}
                        <span>
                          <NumericLabel params={numberOption}>
                            {calculateInput.densityGas}
                          </NumericLabel>
                          &nbsp; lb/ft3{" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {/* ----------- Compressibility Factor ------------- */}

                    {this.props.data.compressibilityError !== undefined &&
                    this.props.data.compressibilityError !== "" ? (
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder={labels.SSA_COMPRESSIBILITY_FACTOR}
                          name="compressibility"
                          onChange={e => {
                            clearError(e);
                          }}
                          onBlur={e => {
                            onBlurInput(e);
                          }}
                          value={labels[this.props.data.compressibilityError]}
                          className="error-input form-control input-box"
                        />
                        {/* ----------- Compressibility Factor error tooltip ------------- */}
                        <div className="error-tooltip">
                          <span>
                            {labels[this.props.data.compressibilityError]}
                          </span>{" "}
                        </div>
                      </div>
                    ) : (
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder={labels.SSA_COMPRESSIBILITY_FACTOR}
                          name="compressibility"
                          onChange={e => {
                            this.props.onChangeInput(e);
                          }}
                          onBlur={e => {
                            onBlurInput(e);
                          }}
                          value={this.props.data.compressibility}
                          className={
                            parseFloat(this.props.data.compressibility) <= 0 ||
                            inputErrors.compressibilityErrorMessage !== ""
                              ? "error-input form-control input-box"
                              : "form-control input-box"
                          }
                        />

                        <div className="error-tooltip">
                          {" "}
                          {inputErrors.compressibilityErrorMessage !== "" ? (
                            <span>
                              {labels[inputErrors.compressibilityErrorMessage]}
                            </span>
                          ) : (
                            <span>{labels.SSA_WARN_COMPRTY_GT_0}</span>
                          )}{" "}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* ---------- molecular weight --------------*/}

                <div className="row">
                  <div className="col-12">
                    <label> {labels.SSA_MOLECULAR_WEIGHT} </label>
                    <input
                      type="text"
                      className="form-control input-box"
                      placeholder={labels.SSA_MOLECULAR_WEIGHT}
                      name="molecularWeight"
                      onChange={e => {
                        this.props.onChangeInput(e);
                      }}
                      onBlur={e => {
                        onBlurInput(e);
                      }}
                      value={this.props.data.molecularWeight}
                      className={
                        parseFloat(this.props.data.molecularWeight) <= 0 ||
                        inputErrors.molecularWeightErrorMessage !== ""
                          ? "error-input form-control input-box"
                          : "form-control input-box"
                      }
                    />
                    {/* ---------- molecular weight error tooltip --------------*/}
                    <div className="error-tooltip">
                      {" "}
                      {inputErrors.molecularWeightErrorMessage !== "" ? (
                        <span>
                          {labels[inputErrors.molecularWeightErrorMessage]}
                        </span>
                      ) : (
                        <span>{labels.SSA_WARN_MOL_WT_GT_0}</span>
                      )}{" "}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ErrorBoundary>
    );
  }
}

export default FluidProperties;
