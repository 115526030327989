/* -- File : PhysicalDetails/constant.js -- */
/* Author : SLK */
/* Description : which contains all constants used in saga and reducer function of physical details */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */

// GET_PIPESIZE_LIST_SUCCESS : Constant for pipe size list success
//  GET_PRESSURECLASS_LIST_SUCCESS : Constant for pressure class list success
//  GET_TRIMTYPE_LIST_SUCCESS : Constant for trim type list success
//  GET_PHYSICALDETAILS_LIST_VALUES_SUCCESS_ROOTSAGA : Constant for physical details list success
//  GET_PHYSICALDETAILS_LIST_LOADING : Constant for physical details list loading
//  GET_PHYSICALDETAILS_LIST_ERROR : Constant for physical details list error

const GET_PIPESIZE_LIST_SUCCESS = "GET_PIPESIZE_LIST_SUCCESS";
const GET_PRESSURECLASS_LIST_SUCCESS = "GET_PRESSURECLASS_LIST_SUCCESS";
const GET_TRIMTYPE_LIST_SUCCESS = "GET_TRIMTYPE_LIST_SUCCESS";

const GET_PHYSICALDETAILS_LIST_VALUES_SUCCESS_ROOTSAGA =
  "GET_PHYSICALDETAILS_LIST_VALUES_SUCCESS_ROOTSAGA";

const GET_PHYSICALDETAILS_LIST_LOADING = "GET_PHYSICALDETAILS_LIST_LOADING";
const GET_PHYSICALDETAILS_LIST_ERROR = "GET_PHYSICALDETAILS_LIST_ERROR";

export {
  GET_PIPESIZE_LIST_SUCCESS,
  GET_PRESSURECLASS_LIST_SUCCESS,
  GET_TRIMTYPE_LIST_SUCCESS,
  GET_PHYSICALDETAILS_LIST_VALUES_SUCCESS_ROOTSAGA,
  GET_PHYSICALDETAILS_LIST_LOADING,
  GET_PHYSICALDETAILS_LIST_ERROR
};
