/* -- File : PhysicalDetails/api.js -- */
/* Author : SLK */
/* Description : API file which contains API calls */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import axios from "axios";
import { API_URL } from "../../utils/constant";

/* Axios instance creation */
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  },
  timeout: 240000
});

/* API function to get pipe size list */
export const fetchPipeSizeListAPI = input => {
  return axiosInstance
    .post("pipeSizesView/", input)
    .then(response => response.data);
};

/* API function to get pressure class list */
export const fetchPressureClassListAPI = input => {
  return axiosInstance
    .post("pressureClassesView/", input)
    .then(response => response.data);
};

/* API function to get trim type list */
export const fetchTrimTypeListAPI = input => {
  return axiosInstance
    .post("trimTypesView/", input)
    .then(response => response.data);
};
