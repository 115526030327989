import "font-awesome/css/font-awesome.min.css";
// @flow

/* -- File : Button.js */
/* Author : SLK */
/* Component Name : Button */
/* Component Type : Function Component */
/* Description : Button component is a functional component, Some info comes in (props), */
/*               and returns button element as output */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React from "react";

/* Component props type declaration */
type ButtonProps = {
  disabledButton?: boolean,
  onclickevent: any,
  classname: string,
  text: string,
  icon?: boolean,
  iconClassName?: string
};

/* Button component function */
/* Which Return the html code of the component */
export default function Button({
  disabledButton,
  onclickevent,
  classname,
  text,
  icon,
  iconClassName
}: ButtonProps) {
  return (
    <button
      data-test="buttonTest"
      disabled={disabledButton}
      onClick={onclickevent}
      className={classname}
    >
      {" "}
      {/* Checking whether icon is enabled or not */}
      {text} {icon === true ? <i className={iconClassName}></i> : ""}{" "}
    </button>
  );
}
