/* -- File : FluidProperties/saga.js -- */
/* Author : SLK */
/* Description : fluid properties saga file, which contains saga functions */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import { put, call } from "redux-saga/effects";
import * as CONSTANTS from "./constant";
import * as Api from "./api";

/* Saga function to get fluid properties list values */
export function* fetchFluidPropertiesListValues(action) {
  /* using try and catch block to handle the error */
  try {
    yield put({
      type: CONSTANTS.GET_FLUIDPROPERTIES_LIST_LOADING
    });

    const liquidData = yield call(
      Api.fetchFluidPropertiesListLiquidValuesAPI,
      action.payload
    );
    const gasData = yield call(
      Api.fetchFluidPropertiesListGasValuesAPI,
      action.payload
    );

    yield put({
      type: CONSTANTS.GET_LIQUID_LIST_SUCCESS,
      liquidData
    });
    yield put({
      type: CONSTANTS.GET_GAS_LIST_SUCCESS,
      gasData
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_FLUIDPROPERTIES_LIST_ERROR,
      message: e.message
    });
  }
}
