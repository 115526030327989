/* -- File : FluidProperties/constant.js */
/* Author : SLK */
/* Description : which contains all constants used in saga and reducer function of fluid properties */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */

// GET_LIQUID_LIST_SUCCESS : Constant for liquid list success
//  GET_GAS_LIST_SUCCESS : Constant for gas list success
//  GET_FLUIDPROPERTIES_LIST_LOADING : Constant for fluid properties list loading
//  GET_FLUIDPROPERTIES_LIST_ERROR : Constant for fluid properties list error
//  GET_FLUIDPROPERTIES_LIST_SAGA : Constant for fluid properties list saga

const GET_LIQUID_LIST_SUCCESS = "GET_LIQUID_LIST_SUCCESS";
const GET_GAS_LIST_SUCCESS = "GET_GAS_LIST_SUCCESS";
const GET_FLUIDPROPERTIES_LIST_LOADING = "GET_FLUIDPROPERTIES_LIST_LOADING";
const GET_FLUIDPROPERTIES_LIST_ERROR = "GET_FLUIDPROPERTIES_LIST_ERROR";
const GET_FLUIDPROPERTIES_LIST_SAGA = "GET_FLUIDPROPERTIES_LIST_SAGA";

/* Exporting constants */
export {
  GET_LIQUID_LIST_SUCCESS,
  GET_GAS_LIST_SUCCESS,
  GET_FLUIDPROPERTIES_LIST_LOADING,
  GET_FLUIDPROPERTIES_LIST_ERROR,
  GET_FLUIDPROPERTIES_LIST_SAGA
};
