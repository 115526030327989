// @flow
/* -- File : Process.js */
/* Author : SLK */
/* Description : Process container which contains process UI and functions */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React from "react";
import NumericLabel from "react-pretty-numbers";
import LabelDropdown from "../../components/LabelDropdown/LabelDropdown";
import { ErrorBoundary } from "../../components/errorBoundary";
import { Dropdown } from "../../components/Dropdown/Dropdown";

/* -- component props type -- */
type ProcessProps = {
  data: {
    upstreamPressureP1: string,
    upstreamPressureP1Uom: string,
    downStreamPressureP2: string,
    downStreamPressureP2uom: string,
    temperatureT1: string,
    temperatureT1Uom: string,
    noiseLimit: string,
    noiseLimitUom: string,
    labelChange: any,
    UOMListValues: any,
    isDownstreamP2: any,
    downStreamPressureP2Uom: any,
    pressureChange: any,
    pressureChangeUom: any
  },
  onChangeInput: any,
  onUomChange: any,
  labels: any,
  labelChange: any,
  UOMListValues: any,
  calculateInput: any,
  onBlurInput: any,
  inputErrors: any
};

/* Which Return the html code of the component */
const Process = ({
  data,
  onChangeInput,
  onUomChange,
  labels,
  labelChange,
  UOMListValues,
  calculateInput,
  onBlurInput,
  inputErrors
}: ProcessProps) => {
  const numberOption = {
    precision: 2,
    commafy: false,
    cssClass: ["numericFormatClass"]
  };

  /* -- function to check upstreampressure input errors -- */
  let upstreamPressureP1ErrorMessageData = "";
  const checkUpstreamPressureHasError = calculateInputData => {
    if (inputErrors.upstreamPressureP1ErrorMessage !== "") {
      upstreamPressureP1ErrorMessageData =
        labels[inputErrors.upstreamPressureP1ErrorMessage];
      return true;
    }

    if (parseFloat(calculateInputData.upstreamPressureP1) <= 0) {
      upstreamPressureP1ErrorMessageData = labels.SSA_WARN_PRES_GT_0;
      return true;
    }
    if (
      parseFloat(calculateInputData.upstreamPressureP1) <=
        parseFloat(calculateInputData.downStreamPressureP2) &&
      data.isDownstreamP2 === "true"
    ) {
      upstreamPressureP1ErrorMessageData = labels.SSA_WARN_P1_GT_P2;
      return true;
    }

    if (
      parseFloat(calculateInputData.upstreamPressureP1) <=
        parseFloat(calculateInputData.pressureChange) &&
      data.isDownstreamP2 === "false"
    ) {
      upstreamPressureP1ErrorMessageData = labels.SSA_WARN_P1_GT_P2;
      return true;
    }

    return false;
  };

  /* -- function to check downstreampressure input errors -- */
  let downStreamPressureP2ErrorMessageData = "";
  const checkDownStreamPressureP2HasError = calculateInputData => {
    if (inputErrors.downStreamPressureP2ErrorMessage !== "") {
      downStreamPressureP2ErrorMessageData =
        labels[inputErrors.downStreamPressureP2ErrorMessage];
      return true;
    }

    if (parseFloat(calculateInputData.downStreamPressureP2) <= 0) {
      downStreamPressureP2ErrorMessageData = labels.SSA_WARN_PRES_GT_0;
      return true;
    }
    if (
      parseFloat(calculateInputData.upstreamPressureP1) <=
      parseFloat(calculateInputData.downStreamPressureP2)
    ) {
      downStreamPressureP2ErrorMessageData = labels.SSA_WARN_P2_LT_P1;
      return true;
    }
    return false;
  };

  /* -- function to check pressure drop input errors -- */
  let pressureDropErrorMessage = "";
  const checkPressureDropHasError = calculateInputData => {
    if (inputErrors.pressureChangeErrorMessage !== "") {
      pressureDropErrorMessage = labels[inputErrors.pressureChangeErrorMessage];
      return true;
    }

    if (parseFloat(calculateInputData.pressureChange) <= 0) {
      pressureDropErrorMessage = labels.SSA_PRES_DROP_GT_0;
      return true;
    }
    if (
      parseFloat(calculateInputData.upstreamPressureP1) <=
      parseFloat(calculateInputData.pressureChange)
    ) {
      pressureDropErrorMessage = labels.SSA_PRES_DROP_GT_P1;
      return true;
    }
    return false;
  };

  return (
    <ErrorBoundary>
      <div data-test="processTest">
        {/* -- upstream pressure p1 -- */}
        <label> {labels.SSA_UP_PRESSURE} </label>

        <div className="row">
          <div className="col-sm-9 col-6">
            {/* -- upstream pressure p1 Input -- */}
            <input
              type="text"
              name="upstreamPressureP1"
              onChange={e => {
                onChangeInput(e);
              }}
              onBlur={e => {
                onBlurInput(e);
              }}
              value={data.upstreamPressureP1}
              className={
                checkUpstreamPressureHasError(calculateInput) === true
                  ? "error-input form-control input-box"
                  : "form-control input-box"
              }
              placeholder={labels.SSA_UP_PRESSURE}
            />
            {/* -- upstream pressure p1 error tooltip -- */}
            <div className="error-tooltip">
              {" "}
              <span>{upstreamPressureP1ErrorMessageData}</span>{" "}
            </div>
          </div>
          <div className="col-sm-3 col-6 upstreamPressureP1UomClass">
            {/* -- upstream pressure p1 UOM drop down -- */}
            <Dropdown
              name="upstreamPressureP1Uom"
              options={UOMListValues.pressure}
              value={data.upstreamPressureP1Uom}
              changedStateValue={uomObj =>
                onUomChange("upstreamPressureP1Uom", uomObj)
              }
              classes="dropdown-tooltip"
            />
            {/* -- upstream pressure p1 dropdown tooltip -- */}
            <div className="dropdown-tooltip-body">
              {" "}
              <span>
                <NumericLabel params={numberOption}>
                  {calculateInput.upstreamPressureP1}
                </NumericLabel>
                &nbsp; psia
              </span>{" "}
            </div>
          </div>
        </div>

        {/* -- downstream pressure p2 and pressure drop -- */}
        <LabelDropdown
          labelname={
            data.isDownstreamP2 === "true"
              ? labels.SSA_DOWN_PRESSURE
              : labels.SSA_PRESSURE_CHANGE
          }
          labelnameList={[labels.SSA_DOWN_PRESSURE, labels.SSA_PRESSURE_CHANGE]}
          labelChange={labelname => labelChange(labelname)}
        />

        {/* -- checking downstream pressure p2 or pressure drop -- */}
        {data.isDownstreamP2 === "true" ? (
          <div className="row">
            {/* -- downstream pressure p2 -- */}
            <div className="col-sm-9 col-6">
              <input
                type="text"
                name="downStreamPressureP2"
                onChange={e => {
                  onChangeInput(e);
                }}
                onBlur={e => {
                  onBlurInput(e);
                }}
                value={data.downStreamPressureP2}
                className={
                  checkDownStreamPressureP2HasError(calculateInput) === true
                    ? "error-input form-control input-box"
                    : "form-control input-box"
                }
                placeholder={labels.SSA_DOWN_PRESSURE}
              />
              {/* -- downstream pressure p2 error tooltip -- */}
              <div className="error-tooltip">
                {" "}
                <span>{downStreamPressureP2ErrorMessageData}</span>{" "}
              </div>
            </div>
            {/* -- downstream pressure p2 UOM dropdown  -- */}
            <div className="col-sm-3 col-6">
              <Dropdown
                name="downStreamPressureP2Uom"
                options={UOMListValues.pressure}
                value={data.downStreamPressureP2Uom}
                changedStateValue={uomObj =>
                  onUomChange("downStreamPressureP2Uom", uomObj)
                }
                classes="dropdown-tooltip"
              />
              {/* -- downstream pressure p2 UOM dropdown tooltip  -- */}
              <div className="dropdown-tooltip-body">
                {" "}
                <span>
                  <NumericLabel params={numberOption}>
                    {calculateInput.downStreamPressureP2}
                  </NumericLabel>
                  &nbsp; psia
                </span>{" "}
              </div>
            </div>
          </div>
        ) : (
          <div className="row ro1">
            {/* -- pressure drop -- */}
            <div className="col-sm-9 col-6">
              <input
                type="text"
                name="pressureChange"
                onChange={e => {
                  onChangeInput(e);
                }}
                onBlur={e => {
                  onBlurInput(e);
                }}
                value={data.pressureChange}
                className={
                  checkPressureDropHasError(calculateInput) === true
                    ? "error-input form-control input-box"
                    : "form-control input-box"
                }
                placeholder={labels.SSA_PRESSURE_CHANGE}
              />
              {/* -- pressure drop error tooltip  -- */}
              <div className="error-tooltip">
                {" "}
                <span>{pressureDropErrorMessage}</span>{" "}
              </div>
            </div>
            {/* -- pressure drop UOM dropdown  -- */}
            <div className="col-sm-3 col-6">
              <Dropdown
                name="pressureChangeUom"
                options={UOMListValues.pressureDrop}
                value={data.pressureChangeUom}
                changedStateValue={uomObj =>
                  onUomChange("pressureChangeUom", uomObj)
                }
                classes="dropdown-tooltip"
              />
              {/* -- pressure drop UOM dropdown tooltip -- */}
              <div className="dropdown-tooltip-body">
                {" "}
                <span>
                  <NumericLabel params={numberOption}>
                    {calculateInput.pressureChange}
                  </NumericLabel>
                  &nbsp; psid
                </span>{" "}
              </div>
            </div>
          </div>
        )}

        {/* -- temperature  -- */}
        <label> {labels.SSA_TEMPERATURE} </label>

        <div className="row">
          <div className="col-sm-9 col-6">
            <input
              type="text"
              name="temperatureT1"
              onChange={e => {
                onChangeInput(e);
              }}
              onBlur={e => {
                onBlurInput(e);
              }}
              value={data.temperatureT1}
              className="form-control input-box"
              placeholder={labels.SSA_TEMPERATURE}
              className={
                calculateInput.temperatureT1 <= 0 ||
                inputErrors.temperatureT1ErrorMessage !== ""
                  ? "error-input form-control input-box"
                  : "form-control input-box"
              }
            />
            {/* -- temperature error tooltip  -- */}
            <div className="error-tooltip">
              {" "}
              {inputErrors.temperatureT1ErrorMessage !== "" ? (
                <span>{labels[inputErrors.temperatureT1ErrorMessage]}</span>
              ) : (
                <span>{labels.SSA_WARN_TEMP_GT_A0}</span>
              )}{" "}
            </div>
          </div>
          {/* -- temperature UOM dropdown  -- */}
          <div className="col-sm-3 col-6">
            <Dropdown
              name="temperatureT1Uom"
              options={UOMListValues.temperature}
              value={data.temperatureT1Uom}
              changedStateValue={uomObj =>
                onUomChange("temperatureT1Uom", uomObj)
              }
              classes="dropdown-tooltip"
            />
            {/* -- temperature UOM dropdown tooltip  -- */}
            <div className="dropdown-tooltip-body">
              {" "}
              <span>
                <NumericLabel params={numberOption}>
                  {calculateInput.temperatureT1}
                </NumericLabel>
                &nbsp; deg R
              </span>{" "}
            </div>
          </div>
        </div>

        {/* -- noise limit  -- */}
        <label> {labels.SSA_NOISE_LIMIT} </label>

        <div className="row">
          <div className="col-sm-9 col-6">
            <input
              type="text"
              name="noiseLimit"
              onChange={e => {
                onChangeInput(e);
              }}
              onBlur={e => {
                onBlurInput(e);
              }}
              value={data.noiseLimit}
              placeholder={labels.SSA_NOISE_LIMIT}
              className={
                inputErrors.noiseLimitErrorMessage !== ""
                  ? "error-input form-control input-box"
                  : "form-control input-box"
              }
            />
            {/* -- Noise limit error tool tip -- */}
            <div className="error-tooltip">
              {" "}
              <span>{labels[inputErrors.noiseLimitErrorMessage]}</span>{" "}
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <Dropdown
              name="noiseLimitUom"
              options={UOMListValues.noiseLimit}
              value={data.noiseLimitUom}
              changedStateValue={uomObj => onUomChange("noiseLimitUom", uomObj)}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Process;
