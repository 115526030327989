// @flow
/* -- File : Result.js -- */
/* Author : SLK */
/* Description : Result container which contains result page UI and functions */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React from "react";
//import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ErrorBoundary } from "../../components/errorBoundary";
//import Button from "../../components/Button/Button";

/* -- component props type -- */

//  labels: contains labels data,
//  isModalOpen: contains boolean value to check modal is opened or not,
//  modalToggle: method for modal toggle,
//  onChangeInput: method which calls on change of input,
//  emailID: contains email ID value,
//  sendMail: method to send mail,
//  mailSentDetails: contains method is sent or not,
//  valveCapcityData: contains valve capacity data,
//  processInputError: contains true/false if any errors,
//  fluidPropertiesInputError: contains true/false if any errors,
//  calculateInput: Contains calculate input data

type ResultProps = {
  labels: any,
  isModalOpen: boolean,
  modalToggle: any,
  onChangeInput: any,
  emailID: string,
  sendMail: any,
  mailSentDetails: any,
  valveCapcityData: any,
  processInputError: boolean,
  fluidPropertiesInputError: boolean,
  calculateInput: any
};

// function to check email validations
let emailValid = true;
const checkEmailValidation = (e: any) => {
  const emailRegEx = /\S+@\S+\.\S+/;
  emailValid = emailRegEx.test(e.target.value);
};

/* method to get significant number */
const roundSig = num => {
  const data = parseFloat(num);
  return parseFloat(data.toPrecision(2));
};

/* Which Return the html code of the component */
const Result = ({
  labels,
  isModalOpen,
  modalToggle,
  onChangeInput,
  emailID,
  sendMail,
  mailSentDetails,
  valveCapcityData,
  processInputError,
  fluidPropertiesInputError,
  calculateInput
}: ResultProps) => {
  return (
    <ErrorBoundary data-test="ResultTest">
      {processInputError === true || fluidPropertiesInputError === true ? (
        <>
          <h4>{labels.SSA_INPUT_ERROR} :</h4>
          <h6 class="errorValue"> {labels.SSA_INPUT_ERROR_MSG} </h6>
        </>
      ) : (
        <>
          <h4> {labels.SSA_CALCULATED_CV} </h4>
          {valveCapcityData.calculateCV.error === "" ? (
            <span>
              {roundSig(valveCapcityData.calculateCV.required_valve_capacity)}
            </span>
          ) : (
            <>
              <span>{labels[valveCapcityData.calculateCV.error]}</span>
            </>
          )}

          <h4 className="mt-4"> {labels.SSA_POSSIBLE_VALVE_SIZES} </h4>

          {valveCapcityData.valveSizeOpenings.error === "" ? (
            valveCapcityData.valveSizeOpenings.valve_sizes_and_openings.map(
              (valveData, index) => {
                return (
                  <React.Fragment key={index}>
                    <span>
                      {valveData[0]} {labels.SSA_INCH_VALVE}, {valveData[1]}{" "}
                      {labels.SSA_OPEN}{" "}
                    </span>
                    <br />
                  </React.Fragment>
                );
              }
            )
          ) : (
            <>
              <span>{labels[valveCapcityData.valveSizeOpenings.error]}</span>
            </>
          )}

          {valveCapcityData.cavitationMessage !== null && (
            <>
              <h4 className="mt-4">{labels.SSA_WARNING}</h4>
              <span>{labels[valveCapcityData.cavitationMessage]}</span>
            </>
          )}

          {valveCapcityData.analyzeNoise !== null && (
            <>
              <h4 className="mt-4">Warnings</h4>
              <span>{labels[valveCapcityData.analyzeNoise]}</span>
            </>
          )}
        </>
      )}

      {/* ------------ Share email modal popup---------- */}
      {/* <Modal isOpen={isModalOpen} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}>
          {labels.SSA_SHARE_RESULT}
        </ModalHeader>
        <ModalBody>
          {labels[mailSentDetails.mailSent] === labels.SSA_MAIL_SENT ? (
            <div className="msgClass">
              {" "}
              {labels[mailSentDetails.mailSent]} {emailID}
            </div>
          ) : (
            <>
              <label>{labels.SSA_EMAIL_ID}</label>
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    placeholder={labels.SSA_EMAIL_ID}
                    name="emailID"
                    onChange={e => {
                      checkEmailValidation(e);
                      onChangeInput(e);
                    }}
                    value={emailID}
                    className={
                      emailValid === false
                        ? "error-input form-control input-box mb-0"
                        : "form-control input-box mb-0"
                    }
                  />

                  <div class="error-tooltip">
                    {" "}
                    <span>{labels.SSA_EMAIL_ERR_MSG}</span>{" "}
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {labels[mailSentDetails.mailSent] === labels.SSA_MAIL_SENT ? (
            <Button
              classname="btn btn-primary pull-right"
              text={labels.SSA_CLOSE}
              onclickevent={() => {
                modalToggle();
              }}
            />
          ) : (
            <Button
              classname="btn btn-primary pull-right sendMailBtn"
              text={labels.SSA_SEND}
              disabledButton={!!(emailValid === false || emailID === "")}
              onclickevent={() => {
                sendMail();
              }}
            />
          )}
        </ModalFooter>
      </Modal> */}
    </ErrorBoundary>
  );
};

export default Result;
