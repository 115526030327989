/* -- File : common/saga.js */
/* Author : SLK */
/* Description : common saga file, which contains common saga functions */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import { put, call, select, all } from "redux-saga/effects";
import * as CONSTANTS from "./constant";
import * as Api from "./api";

/* Getting store values */
export const getCalcualteInputData = state => state.getCalcualteInputData;
export const getDefaultValues = state => state.getDefaultValues;
export const inputErrors = state => state.inputErrors;

/* Saga function to fetch labels based on language */
export function* fetchLabels(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_LABELS_DATA_LOADING
    });

    /* Calling Api */
    const data = yield call(Api.fetchLabelsAPI, action.lang);
    data.showLoader = false;
    yield put({
      type: CONSTANTS.GET_LABELS_DATA_SUCCESS,
      data
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_LABELS_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to fetch UOM list values */
export function* fetchUomList(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_UOMLIST_DATA_LOADING
    });

    /* Calling Api */
    const data = yield call(Api.fetchUomListAPI);

    yield put({
      type: CONSTANTS.GET_UOMLIST_SUCCESS,
      data
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_UOMLIST_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to fetch default values */
export function* fetchDefaultValues(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_LOADING
    });
    /* Calling Api */
    const data = yield call(Api.fetchDefaultValuesAPI);

    // base value for default units
    const calcualteInputData = Object.assign({}, data);
    calcualteInputData.upstreamPressureP1 = "114.69";
    calcualteInputData.downStreamPressureP2 = "24.689999999999998";
    calcualteInputData.temperatureT1 = "559.67";
    calcualteInputData.flowrate = "60000";
    calcualteInputData.flowrateGas = "60000";

    data.showLoader = false;
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_SUCCESS,
      data
    });

    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_SUCCESS,
      calcualteInputData
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to convert pressure UOM */
export function* fetchPressureUOMConversion(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_LOADING
    });
    const { changedFieldName, calcualteInputData } = action.data;
    /* Calling Api */
    const data = yield call(Api.fetchPressureUOMConversionAPI, {
      value: action.data.value,
      old_unit: action.data.old_unit,
      new_unit: action.data.new_unit
    });

    calcualteInputData[changedFieldName] =
      data === undefined ? 0 : data.converted_value;

    const defaultData = yield select(getDefaultValues);

    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_SUCCESS,
      calcualteInputData
    });

    if (changedFieldName === "upstreamPressureP1") {
      /* Calling fluid constant function to get density and vapor pressure value on change in temperature field */
      yield put({
        type: CONSTANTS.GET_FLUID_CONSTANTS_DATA_SAGA,
        data: {
          fluid_name:
            defaultData.selectedTab === "1"
              ? defaultData.liquidName
              : defaultData.gasName,
          default_data: defaultData,
          calcualteInputData
        }
      });
    } else {
      // Calling valve capacity calculate function on change in Input
      yield put({
        type: CONSTANTS.GET_RESULT_SAGA,
        data: {
          defaultData,
          calculateInput: calcualteInputData
        }
      });
    }
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to convert temperature UOM */
export function* fetchTemperatureUOMConversion(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_LOADING
    });
    const { changedFieldName, calcualteInputData } = action.data;
    /* Calling Api */
    const data = yield call(Api.fetchTemperatureUOMConversionAPI, {
      value: action.data.value,
      old_unit: action.data.old_unit,
      new_unit: action.data.new_unit
    });

    calcualteInputData[changedFieldName] =
      data === undefined ? 0 : data.converted_value;

    const defaultData = yield select(getDefaultValues);

    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_SUCCESS,
      calcualteInputData
    });

    /* Calling fluid constant function to get density and vapor pressure value on change in temperature field */
    yield put({
      type: CONSTANTS.GET_FLUID_CONSTANTS_DATA_SAGA,
      data: {
        fluid_name:
          defaultData.selectedTab === "1"
            ? defaultData.liquidName
            : defaultData.gasName,
        default_data: defaultData,
        calcualteInputData
      }
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to convert pressure drop UOM */
export function* fetchPressureDropUOMConversion(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_LOADING
    });
    const { changedFieldName, calcualteInputData } = action.data;
    /* Calling Api */
    const data = yield call(Api.fetchPressureDropUOMConversionAPI, {
      value: action.data.value,
      old_unit: action.data.old_unit,
      new_unit: action.data.new_unit
    });

    calcualteInputData[changedFieldName] =
      data === undefined ? 0 : data.converted_value;

    // Calling valve capacity calculate function on change in Input
    yield put({
      type: CONSTANTS.GET_RESULT_SAGA,
      data: {
        defaultData: yield select(getDefaultValues),
        calculateInput: calcualteInputData
      }
    });

    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_SUCCESS,
      calcualteInputData
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to convert flow rate UOM */
export function* fetchFlowRateUOMConversion(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_LOADING
    });
    const { changedFieldName, calcualteInputData } = action.data;
    /* Calling Api */
    const data = yield call(Api.fetchFlowRateUOMConversionAPI, {
      value: action.data.value,
      old_unit: action.data.old_unit
    });

    calcualteInputData[changedFieldName] =
      data === undefined ? 0 : data.converted_value;

    if (changedFieldName === "flowrate" || changedFieldName === "flowrateGas") {
      calcualteInputData.flowrateBaseUnit =
        data === undefined ? 0 : data.base_unit;
    }

    // Calling valve capacity calculate function on change in Input
    yield put({
      type: CONSTANTS.GET_RESULT_SAGA,
      data: {
        defaultData: yield select(getDefaultValues),
        calculateInput: calcualteInputData
      }
    });

    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_SUCCESS,
      calcualteInputData
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to convert density UOM */
export function* fetchDensityUOMConversion(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_LOADING
    });
    const { changedFieldName, calcualteInputData } = action.data;
    /* Calling Api */
    const data = yield call(Api.fetchDensityUOMConversionAPI, {
      value: action.data.value,
      old_unit: action.data.old_unit,
      new_unit: action.data.new_unit
    });

    calcualteInputData[changedFieldName] =
      data === undefined ? 0 : data.converted_value;

    let defaultValues = yield select(getDefaultValues);

    /* For jest testing */
    if (defaultValues === undefined) {
      defaultValues = {};
      defaultValues.selectedTab = "1";
      defaultValues.isDensity = "true";
    }

    /* Calling specific gravity calculation bcoz density is changed only when selected tab is liquid */
    let specifiGravitydata;
    if (
      defaultValues.selectedTab === "1" &&
      defaultValues.isDensity === "true"
    ) {
      // LIQUID
      yield put({
        type: CONSTANTS.GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY,
        data: {
          value: parseFloat(defaultValues.densityLiquid),
          unit: action.data.old_unit,
          grav_dens: "density",
          default_data: defaultValues
        }
      });
      /* Calling Api */
      specifiGravitydata = yield call(
        Api.fetchConvertedDensitySpecificGravityAPI,
        {
          value: parseFloat(defaultValues.densityLiquid),
          unit: action.data.old_unit,
          grav_dens: "density"
        }
      );

      defaultValues.specificGravity =
        specifiGravitydata.converted_value === undefined
          ? "ERROR"
          : specifiGravitydata.converted_value;

      if (specifiGravitydata.error !== "") {
        defaultValues.densityLiquid = "ERROR";
        defaultValues.specificGravity = "ERROR";
      }
    }

    // Calling valve capacity calculate function on change in Input
    yield put({
      type: CONSTANTS.GET_RESULT_SAGA,
      data: {
        defaultData: defaultValues,
        calculateInput: calcualteInputData
      }
    });

    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_SUCCESS,
      calcualteInputData
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to get density, vapor pressure, critical pressure, molecular weight on change of fluid name */
export function* fetchFluidConstants(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_LOADING
    });
    /* Calling Api */
    let fluidConstantData = yield call(Api.fetchFluidConstantsAPI, {
      fluid_name: action.data.fluid_name
    });

    //    const data = action.data.default_data;
    let data = yield select(getDefaultValues);

    const { calcualteInputData } = action.data;

    /* For jest testing */
    if (data === undefined) {
      data = action.data.default_data;
    }

    if (fluidConstantData === undefined) {
      fluidConstantData = {
        critical_pressure: 1100,
        vapor_pressure: 50,
        specific_gravity: 0.8,
        pressure_base_unit: "psia"
      };
    } else if (
      fluidConstantData === undefined &&
      action.data.fluid_name === "Other"
    ) {
      fluidConstantData = {
        critical_pressure: 3200,
        vapor_pressure: 0.95,
        density_liquid: 62.418,
        density_gas: 0.2,
        molecular_weight: 18.01,
        pressure_base_unit: "psia",
        density_base_unit: "lb/ft3"
      };
    }

    if (data.selectedTab === "1") {
      /* -------------------------------------- LIQUID ----------------------------------------- */

      let densityData;
      let vaporPressureData;

      /* Reseting liquid error variable */
      data.densityLiquidError = "";
      data.vaporPressurePVError = "";

      /* setting label to density */
      data.isDensity = "true";

      /* Setting critical pressure value (on any change in liquid name we will get critical pressure in fluidconstantAPI) */
      data.criticalPressurePC = fluidConstantData.critical_pressure;
      data.criticalPressurePCUom = fluidConstantData.pressure_base_unit;
      calcualteInputData.criticalPressurePC =
        fluidConstantData.critical_pressure;

      /* If liquid name in typical crude oil we will get vapor pressure and specific gravity value in fluidconstantAPI  */
      if (action.data.fluid_name === "Typical Crude Oil") {
        /* setting default data */
        data.vaporPressurePV = fluidConstantData.vapor_pressure;
        data.specificGravity = fluidConstantData.specific_gravity;
        data.vaporPressurePVUom = fluidConstantData.pressure_base_unit;

        /* setting calculate data */
        calcualteInputData.vaporPressurePV = fluidConstantData.vapor_pressure;
        calcualteInputData.specificGravity = fluidConstantData.specific_gravity;

        /* In typical crude oil specific gravity has to be selected */
        data.isDensity = "false";
      } else if (action.data.fluid_name === "Other") {
        /* If liquid name is Other we will get vapor pressure and density value in fluidconstantAPI  */
        /* setting default data */
        data.vaporPressurePV = fluidConstantData.vapor_pressure;
        data.densityLiquid = fluidConstantData.density_liquid;
        data.densityLiquidUom = fluidConstantData.density_base_unit;
        data.vaporPressurePVUom = fluidConstantData.pressure_base_unit;

        /* setting calculate data */
        calcualteInputData.vaporPressurePV = fluidConstantData.vapor_pressure;
        calcualteInputData.densityLiquid = fluidConstantData.density_liquid;

        /* In others density has to be selected */
        data.isDensity = "true";
      } else if (action.data.fluid_name === "Water") {
        /* If water calling *waterSteamProperties/liquidDensity/* to get densityLiquid value and
         *waterSteamProperties/vapourPressure/* to get vapor pressure value
         */

        [densityData, vaporPressureData] = yield all([
          call(Api.waterSteamLiquidDensityApi, {
            temperature: parseFloat(calcualteInputData.temperatureT1),
            pressure: parseFloat(calcualteInputData.upstreamPressureP1)
          }),
          call(Api.waterSteamVaporPressureApi, {
            temperature: parseFloat(calcualteInputData.temperatureT1)
          })
        ]);
      } else {
        /* Else if non-water (except Other & typical crude Oil) *fluidProperties/liquidDensity/*
         * to get densityLiquid value
         * and *fluidProperties/vapourPressure/* to get vapor pressure value
         */

        [densityData, vaporPressureData] = yield all([
          call(Api.fluidPropertiesLiquidDensityApi, {
            temperature: parseFloat(calcualteInputData.temperatureT1),
            liquid: action.data.fluid_name
          }),
          call(Api.fluidPropertiesVaporPressureApi, {
            temperature: parseFloat(calcualteInputData.temperatureT1),
            liquid: action.data.fluid_name
          })
        ]);
      }

      /* calling density and vapor pressure uom conversion only if fluid is not equal to 'typical crude oil' and 'other'
       * because we will get value in base UOM in both this fluids so no need of conversion
       */
      if (
        action.data.fluid_name !== "Typical Crude Oil" &&
        action.data.fluid_name !== "Other"
      ) {
        // setting density liquid value and UOM
        data.densityLiquid =
          densityData.density === undefined ? "ERROR" : densityData.density;
        data.densityLiquidError = densityData.error;
        data.densityLiquidUom = densityData.unit;

        // setting vapor pressure value and UOM
        data.vaporPressurePV =
          vaporPressureData.vapor_pressure === undefined
            ? "ERROR"
            : vaporPressureData.vapor_pressure;
        data.vaporPressurePVUom = vaporPressureData.unit;
        data.vaporPressurePVError = vaporPressureData.error;

        //        if (densityData.error === "" && vaporPressureData.error === "") {
        const [densityUomConversionData, pressureConvertedData] = yield all([
          call(Api.fetchDensityUOMConversionAPI, {
            value: densityData.density,
            old_unit: densityData.unit,
            new_unit: "lb/ft3"
          }),
          call(Api.fetchPressureUOMConversionAPI, {
            value: vaporPressureData.vapor_pressure,
            old_unit: vaporPressureData.unit,
            new_unit: "psia"
          })
        ]);

        calcualteInputData.densityLiquid =
          densityUomConversionData.converted_value;
        calcualteInputData.vaporPressurePV =
          pressureConvertedData.converted_value;
        //        }
      }

      /* ------If any changes in density value we need to call specific  gravity------*/

      let valuedata;
      let gravDens;
      if (data.isDensity === "true") {
        valuedata =
          data.densityLiquid === "ERROR"
            ? data.densityLiquidError
            : parseFloat(data.densityLiquid);
        gravDens = "density";
      } else {
        valuedata = data.specificGravity;
        gravDens = "specific_gravity";
      }

      //      const valuedata =
      //        data.isDensity === "true"
      //          ? data.densityLiquid === "ERROR"
      //            ? data.densityLiquidError
      //            : parseFloat(data.densityLiquid)
      //          : data.specificGravity;

      //      yield put({
      //        type: CONSTANTS.GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY,
      //        data: {
      //          value: valuedata,
      //          unit: data.densityLiquidUom,
      //          grav_dens: gravDens,
      //          default_data: data
      //        }
      //      });
      /* Calling Api */
      const convertedData = yield call(
        Api.fetchConvertedDensitySpecificGravityAPI,
        {
          value: valuedata,
          unit: data.densityLiquidUom,
          grav_dens: gravDens
        }
      );

      if (gravDens === "specific_gravity") {
        data.densityLiquid =
          convertedData.converted_value === undefined
            ? "ERROR"
            : convertedData.converted_value;

        yield put({
          type: CONSTANTS.GET_DENSITY_UOM_CONVERSION_DATA_SAGA,
          data: {
            value: convertedData.converted_value,
            old_unit: data.densityLiquidUom,
            new_unit: "lb/ft3",
            changedFieldName: "densityLiquid",
            calcualteInputData: yield select(getCalcualteInputData)
          }
        });
      } else {
        data.specificGravity =
          convertedData.converted_value === undefined
            ? "ERROR"
            : convertedData.converted_value;
      }

      if (convertedData.error !== "") {
        data.densityLiquid = "ERROR";
        data.specificGravity = "ERROR";
      }

      data.specificGravityError = convertedData.error;
      data.densityLiquidError = convertedData.error;

      const inputErrorsData = yield select(inputErrors);

      inputErrorsData.specificGravityErrorMessage = "";
      inputErrorsData.densityLiquidErrorMessage = "";

      yield put({
        type: CONSTANTS.GET_INPUT_ERRORS_SUCCESS,
        inputErrorsData
      });

      /*---------------------------------------------------------------------------*/
    } else {
      /* ----------------------------------- GAS -------------------------------------------- */

      let gasDensityData;
      let compressibilityFactorData;

      /* Reseting gas error variable */
      data.densityGasError = "";
      data.compressibilityError = "";

      /* Setting molecular weight value (on any change in gas name we will get molecular weight in fluidconstantAPI) */
      data.molecularWeight = fluidConstantData.molecular_weight;
      calcualteInputData.molecularWeight = fluidConstantData.molecular_weight;

      /* If gas name is Other we will get gas density and molecular weight in fluidconstantAPI  */
      if (action.data.fluid_name === "Other") {
        /* setting default data */
        data.densityGas = fluidConstantData.density_gas;
        data.densityGasUom = fluidConstantData.density_base_unit;

        /* setting calculate data */
        calcualteInputData.densityGas = fluidConstantData.density_gas;
      } else if (action.data.fluid_name === "Steam") {
        /* If steam calling  *waterSteamProperties/steamDensity/* to get gas density value */
        /* Calling Api */
        gasDensityData = yield call(Api.waterSteamDensityApi, {
          temperature: parseFloat(calcualteInputData.temperatureT1),
          pressure: parseFloat(calcualteInputData.upstreamPressureP1),
          gas: action.data.fluid_name
        });
      } else {
        /* Else if non steam (except Other) calling
         *fluidProperties/compressibilityFactor/*  to get compressibilityFactor value
         *fluidProperties/gasDensity/* to get gas density value */
        /* Calling Api */
        compressibilityFactorData = yield call(
          Api.fluidPropertiesCompressibilityApi,
          {
            temperature: parseFloat(calcualteInputData.temperatureT1),
            pressure: parseFloat(calcualteInputData.upstreamPressureP1),
            gas: action.data.fluid_name
          }
        );

        data.compressibility =
          compressibilityFactorData.error !== ""
            ? "ERROR"
            : compressibilityFactorData.compressibility_factor;
        data.compressibilityError = compressibilityFactorData.error;
        /* Calling Api */
        gasDensityData = yield call(Api.fluidPropertiesGasDensityApi, {
          temperature: parseFloat(calcualteInputData.temperatureT1),
          pressure: parseFloat(calcualteInputData.upstreamPressureP1),
          gas: action.data.fluid_name,
          compressibility_factor: data.compressibility
        });
      }

      /* calling gas density uom conversion only if fluid is not equal to 'other'
       * because we will get value in base UOM in 'other' so no need of conversion
       */
      if (action.data.fluid_name !== "Other") {
        // setting density gas and uom value
        data.densityGas =
          gasDensityData.density === undefined
            ? "ERROR"
            : gasDensityData.density;
        data.densityGasError = gasDensityData.error;
        data.densityGasUom = gasDensityData.unit;

        // calling density uom conversion on density data change
        //        yield put({
        //          type: CONSTANTS.GET_DENSITY_UOM_CONVERSION_DATA_SAGA,
        //          data: {
        //            value: gasDensityData.density,
        //            old_unit: gasDensityData.unit,
        //            new_unit: "lb/ft3",
        //            changedFieldName: "densityGas",
        //            calcualteInputData
        //          }
        //        });
        /* Calling Api */
        const densityUomConversionData = yield call(
          Api.fetchDensityUOMConversionAPI,
          {
            value: gasDensityData.density,
            old_unit: gasDensityData.unit,
            new_unit: "lb/ft3"
          }
        );

        calcualteInputData.densityGas =
          densityUomConversionData.converted_value;
      }
    }

    data.showLoader = false;

    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_SUCCESS,
      data
    });

    /* calling calculateInputData success saga function only in below condition for other conditions
     * we are calling UOM conversion function so inside UOM conversion
     * we are calling calculateInputData success saga function so no need to call here
     */
    //    if (
    //      (action.data.fluid_name === "Typical Crude Oil" &&
    //        data.selectedTab === "1") ||
    //      action.data.fluid_name === "Other"
    //    ) {
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_SUCCESS,
      calcualteInputData
    });
    //    }

    /* Calling specific gravity calculation bcoz density is changed only when selected tab is liquid */
    //    if (data.selectedTab === "1") {
    //      // LIQUID
    //      const gravDens = data.isDensity === "true"?"density":"specific_gravity"
    //      const valuedata = data.isDensity === "true"
    //              ?
    //                data.densityLiquid === "ERROR"? data.densityLiquidError: parseFloat(data.densityLiquid)
    //              : data.specificGravity;
    //
    //
    //      yield put({
    //        type: CONSTANTS.GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY,
    //        data: {
    //          value: valuedata,
    //          unit: data.densityLiquidUom,
    //          grav_dens: gravDens,
    //          default_data: data
    //        }
    //      });
    //    }

    const defaultData = yield select(getDefaultValues);
    // Calling valve capacity calculate function on change in Input
    if (
      defaultData.liquidName !== "Typical Crude Oil" ||
      (defaultData.isDensity === "true" &&
        defaultData.liquidName === "Typical Crude Oil")
    ) {
      yield put({
        type: CONSTANTS.GET_RESULT_SAGA,
        data: {
          defaultData,
          calculateInput: calcualteInputData
        }
      });
    }

    // clearing empty error messages
    const inputErrorsData = yield select(inputErrors);

    inputErrorsData.vaporPressurePVErrorMessage =
      defaultData.vaporPressurePV !== "" && "";
    inputErrorsData.criticalPressurePCErrorMessage =
      defaultData.criticalPressure !== "" && "";
    inputErrorsData.molecularWeightErrorMessage =
      defaultData.molecularWeightErrorMessage !== "" && "";
    inputErrorsData.densityGasErrorMessage =
      defaultData.densityGasErrorMessage !== "" && "";
    inputErrorsData.compressibilityErrorMessage =
      defaultData.compressibilityErrorMessage !== "" && "";

    yield put({
      type: CONSTANTS.GET_INPUT_ERRORS_SUCCESS,
      inputErrorsData
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to send mail (share result) */
export function* sendMailSaga(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.SEND_MAIL_LOADING
    });
    /* Calling Api */
    const data = yield call(Api.sendMailSagaAPI, action.data);

    yield put({
      type: CONSTANTS.SEND_MAIL_SUCCESS,
      data
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.SEND_MAIL_ERROR,
      message: e.message
    });
  }
}

/* Saga function to convert density to specific gravity and vice versa */
export function* fetchConvertedDensitySpecificGravity(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_LOADING
    });

    const calcualteInputData = yield select(getCalcualteInputData);
    const data = action.data.default_data;
    /* Calling Api */
    const convertedData = yield call(
      Api.fetchConvertedDensitySpecificGravityAPI,
      action.data
    );

    if (action.data.grav_dens === "specific_gravity") {
      data.densityLiquid =
        convertedData.converted_value === undefined
          ? "ERROR"
          : convertedData.converted_value;

      yield put({
        type: CONSTANTS.GET_DENSITY_UOM_CONVERSION_DATA_SAGA,
        data: {
          value: convertedData.converted_value,
          old_unit: data.densityLiquidUom,
          new_unit: "lb/ft3",
          changedFieldName: "densityLiquid",
          calcualteInputData
        }
      });
    } else {
      data.specificGravity =
        convertedData.converted_value === undefined
          ? "ERROR"
          : convertedData.converted_value;
    }

    if (convertedData.error !== "") {
      data.densityLiquid = "ERROR";
      data.specificGravity = "ERROR";
    }

    data.specificGravityError = convertedData.error;
    data.densityLiquidError = convertedData.error;

    const inputErrorsData = yield select(inputErrors);

    inputErrorsData.specificGravityErrorMessage = "";
    inputErrorsData.densityLiquidErrorMessage = "";

    yield put({
      type: CONSTANTS.GET_INPUT_ERRORS_SUCCESS,
      inputErrorsData
    });

    data.showLoader = false;
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_SUCCESS,
      data
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to get result of calculated Cv */
export function* fetchRequiredValveCapacity(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_RESULT_LOADING
    });

    /* calculating delta p value and p2 value */
    const deltaP =
      action.data.defaultData.isDownstreamP2 === "true"
        ? parseFloat(action.data.calculateInput.upstreamPressureP1, 10) -
          parseFloat(action.data.calculateInput.downStreamPressureP2, 10)
        : parseFloat(action.data.calculateInput.pressureChange);

    const p2 =
      action.data.defaultData.isDownstreamP2 === "true"
        ? parseFloat(action.data.calculateInput.downStreamPressureP2)
        : parseFloat(action.data.calculateInput.upstreamPressureP1, 10) -
          parseFloat(action.data.calculateInput.pressureChange, 10);

    /* ---------- valve data API call -------*/
    /* Calling Api */
    const valveData = yield call(Api.fetchValveDataAPI, {
      trim_type: action.data.defaultData.trimType,
      pipe_size: parseInt(action.data.defaultData.pipeSize, 10),
      pres_class: parseInt(action.data.defaultData.pressureValue, 10),
      delta_p: deltaP
    });

    let data;
    /* Checks Valve data as any error */
    if (valveData.error === "") {
      /* ---- Required Cv API call ---- */
      const inputParam = {
        fluid: action.data.defaultData.selectedTab === "1" ? "LIQUID" : "GAS",
        p1: parseFloat(action.data.calculateInput.upstreamPressureP1),
        p2,
        vapor_press: parseFloat(action.data.calculateInput.vaporPressurePV),
        critical_press: parseFloat(
          action.data.calculateInput.criticalPressurePC
        ),
        flow_rate:
          action.data.defaultData.selectedTab === "1"
            ? parseFloat(action.data.defaultData.flowrate)
            : parseFloat(action.data.defaultData.flowrateGas),
        flow_rate_unit:
          action.data.defaultData.selectedTab === "1"
            ? action.data.defaultData.flowrateUom
            : action.data.defaultData.flowrateGasUom,
        spec_gravity: parseFloat(action.data.defaultData.specificGravity),
        mol_wt: parseFloat(action.data.calculateInput.molecularWeight),
        inlet_density:
          action.data.defaultData.selectedTab === "1"
            ? parseFloat(action.data.calculateInput.densityLiquid)
            : parseFloat(action.data.calculateInput.densityGas),
        xt: valveData.xt,
        fl: valveData.fl
      };

      /* ---- Analyze Cavitation API call ---- */
      const analyzeCavitationinputParam = {
        p1: parseFloat(action.data.calculateInput.upstreamPressureP1),
        vapor_press: parseFloat(action.data.calculateInput.vaporPressurePV),
        trim_type: action.data.defaultData.trimType,
        delta_p: deltaP,
        kc: valveData.kc
      };

      let calculatedCvData;
      let analyzeCavitationData = { cavitation_msg: null };
      let analyzeNoise = { noise_msg: null };
      if (action.data.defaultData.selectedTab === "1") {
        // LIQUID
        [calculatedCvData, analyzeCavitationData] = yield all([
          call(Api.fetchRequiredValveCapacityAPI, inputParam),
          call(Api.analyzeCavitationAPI, analyzeCavitationinputParam)
        ]);
      } else {
        // GAS
        /* Calling Api */
        calculatedCvData = yield call(
          Api.fetchRequiredValveCapacityAPI,
          inputParam
        );

        /* ---- Analyze Noise API call ---- */
        /* Calling Api */
        analyzeNoise = yield call(Api.fetchAnalyzeNoiseAPI, {
          pipe_size: parseInt(action.data.defaultData.pipeSize, 10),
          required_cv: calculatedCvData.required_valve_capacity,
          pressure_class: parseInt(action.data.defaultData.pressureValue, 10),
          trim_type: action.data.defaultData.trimType,
          noise_limit: parseFloat(action.data.defaultData.noiseLimit),
          p1: parseFloat(action.data.calculateInput.upstreamPressureP1),
          p2,
          xt: valveData.xt
        });
      }

      /* ---------- valve openings API call -------*/
      /* Calling Api */
      const valveSizeOpenenings = yield call(Api.fetchvalveSizesOpeningsAPI, {
        trim_type: action.data.defaultData.trimType,
        req_cv: calculatedCvData.required_valve_capacity,
        max_cv_list: valveData.valve_sizes_with_max_cv
      });

      data = {
        calculatedCvData,
        analyzeCavitationData,
        analyzeNoise,
        valveSizeOpenenings
      };
    } else {
      const calculatedCvData = {
        error: valveData.error,
        required_valve_capacity: ""
      };
      const analyzeCavitationData = { cavitation_msg: null };
      const analyzeNoise = { noise_msg: null };
      const valveSizeOpenenings = {
        error: valveData.error,
        valve_sizes_and_openings: ""
      };
      data = {
        calculatedCvData,
        analyzeCavitationData,
        analyzeNoise,
        valveSizeOpenenings
      };
    }

    yield put({
      type: CONSTANTS.GET_RESULT_SUCCESS,
      data
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_RESULT_ERROR,
      message: e.message
    });
  }
}

/* Saga function to convert flow rate UOM */
export function* calculateGasDensity(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_LOADING
    });

    const defaultValues = yield select(getDefaultValues);
    const calculateInputData = yield select(getCalcualteInputData);
    /* Calling Api */
    const gasDensityData = yield call(Api.fluidPropertiesGasDensityApi, {
      temperature: parseFloat(calculateInputData.temperatureT1),
      pressure: parseFloat(calculateInputData.upstreamPressureP1),
      gas: defaultValues.gasName,
      compressibility_factor: parseFloat(action.data.compressibility_factor)
    });
    /* Calling Api */
    const densityUomConversionData = yield call(
      Api.fetchDensityUOMConversionAPI,
      {
        value: gasDensityData.density,
        old_unit: gasDensityData.unit,
        new_unit: "lb/ft3"
      }
    );

    calculateInputData.densityGas = densityUomConversionData.converted_value;
    defaultValues.densityGas =
      densityUomConversionData.converted_value === undefined
        ? "ERROR"
        : densityUomConversionData.converted_value;
    defaultValues.densityGasUom = gasDensityData.unit;
    defaultValues.densityGasError = gasDensityData.error;

    /* Reseting density gas empty error */
    const inputErrorsData = yield select(inputErrors);

    inputErrorsData.densityGasErrorMessage = "";

    yield put({
      type: CONSTANTS.GET_INPUT_ERRORS_SUCCESS,
      inputErrorsData
    });
    /*-----------------------------*/

    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_SUCCESS,
      calcualteInputData: calculateInputData
    });
    yield put({
      type: CONSTANTS.GET_DEFAULT_DATA_SUCCESS,
      data: defaultValues
    });

    // Calling valve capacity calculate function on change in Input
    yield put({
      type: CONSTANTS.GET_RESULT_SAGA,
      data: {
        defaultData: defaultValues,
        calculateInput: calculateInputData
      }
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_CALCULATE_INPUT_DATA_ERROR,
      message: e.message
    });
  }
}

/* Saga function to send mail (share result) */
export function* getLanguageListSaga(action) {
  /* using try and catch block to handle the error */
  try {
    /* Dispatching loader event to show the loader */
    yield put({
      type: CONSTANTS.GET_LANGUAGE_LIST_LOADING
    });
    /* Calling Api */
    const data = yield call(Api.getLanguageListAPI);

    yield put({
      type: CONSTANTS.GET_LANGUAGE_LIST_SUCCESS,
      data
    });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({
      type: CONSTANTS.GET_LANGUAGE_LIST_ERROR,
      message: e.message
    });
  }
}
