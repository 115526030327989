/* -- File : routes.js -- */
/* Author :SLK */
/* Year : 2019 */
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./container/Home/Home";

function redirectToHome({ history }) {
  history.push("/sizing");
}

const Routes = () => (
  <Router>
    <>
      <Route path="/" exact={true} render={redirectToHome} />

      <Route path="/sizing" component={Home} />
    </>
  </Router>

  //     <Router>
  //         <Security issuer='https://dev-663758.okta.com/oauth2/default'
  //                   client_id='0oasui9ph1hfA60e1356'
  //                   redirect_uri={window.location.origin + '/implicit/callback'}
  //                   onAuthRequired={onAuthRequired} >
  //           {/* <Route path='/' exact={true} component={Home} /> */}

  //  <Route path="/" exact={true} render={redirectToHome}/>

  //           <Route path='/Home' component={Home} />
  //           <Route path='/login' render={() => <Login baseUrl='https://dev-663758.okta.com' />} />
  //           <Route path='/implicit/callback' component={ImplicitCallback} />
  //         </Security>
  //       </Router>
);

export default Routes;
