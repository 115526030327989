import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { APPINSIGHTS_INSTRUMENTATIONKEY } from "./constant";

class TelemetryService {
  constructor() {
    this.reactPlugin = new ReactPlugin();
  }

  initialize(reactPluginConfig) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APPINSIGHTS_INSTRUMENTATIONKEY,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: reactPluginConfig
        },
        isCookieUseDisabled: true
      }
    });

    if (APPINSIGHTS_INSTRUMENTATIONKEY) {
      this.appInsights.loadAppInsights();
    }
  }
}

export const ai = new TelemetryService();
