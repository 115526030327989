/* -- File : rootReducer.js -- */
/* Author : SLK */
/* Description : main reducer file which combines all the reducers */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import { combineReducers } from "redux";
import { getPhysicalDetailsListValues } from "./PhysicalDetails/index";
import {
  getLabelsData,
  getUOMList,
  getDefaultValues,
  getCalcualteInputData,
  mailSentDetails,
  calculatedResult,
  inputErrors,
  languageList
} from "./common/index";
import { getFluidPropertiesListValues } from "./FluidProperties/index";

const rootReducer = combineReducers({
  getPhysicalDetailsListValues,
  getLabelsData,
  getFluidPropertiesListValues,
  getUOMList,
  getDefaultValues,
  getCalcualteInputData,
  mailSentDetails,
  calculatedResult,
  inputErrors,
  languageList
});

export default rootReducer;
