/* -- File : common/constant.js */
/* Author : SLK */
/* Description : which contains all constants used in saga and reducer function */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */

//  GET_LABELS_DATA_LOADING : Constant for label data loading
//  GET_LABELS_DATA_SUCCESS : Constant for label data success
//  GET_LABELS_DATA_ERROR : Constant for label data error
//  GET_LABELS_DATA_SUCCESS_ROOTSAGA : Constant for label data success saga
//  GET_UOMLIST_SUCCESS : Constant for UOM list success
//  GET_UOMLIST_DATA_LOADING : Constant for UOM list loading
//  GET_UOMLIST_DATA_ERROR : Constant for UOM list error
//  GET_UOMLIST_SAGA : Constant for UOM list saga
//  GET_DEFAULT_DATA_SUCCESS : Constant for default data success
//  GET_DEFAULT_DATA_LOADING : Constant for default data loading
//  GET_DEFAULT_DATA_ERROR : Constant for default data error
//  GET_DEFAULT_DATA_SAGA : Constant for default data saga
//  GET_PRESSURE_UOM_CONVERSION_DATA_SAGA : Constant for UOM conversion data saga
//  GET_PRESSURE_DROP_UOM_CONVERSION_DATA_SAGA : Constant for pressure drop conversion
//  GET_CALCULATE_INPUT_DATA_SUCCESS : Constant for calculate input success
//  GET_CALCULATE_INPUT_DATA_LOADING : Constant for calculate input loading
//  GET_CALCULATE_INPUT_DATA_ERROR : Constant for calculate input error
//  GET_TEMPERATURE_UOM_CONVERSION_DATA_SAGA : Constant for temperature UOM conversion
//  GET_FLOWRATE_UOM_CONVERSION_DATA_SAGA : Constant for flow rate UOM conversion
//  GET_DENSITY_UOM_CONVERSION_DATA_SAGA : Constant for density UOM conversion
//  GET_FLUID_CONSTANTS_DATA_SAGA : Constant for fluid constants
//  SEND_MAIL_SUCCESS : Constant for mail success
//  SEND_MAIL_LOADING : Constant for mail loading
//  SEND_MAIL_ERROR : Constant for mail error
//  SEND_MAIL_SAGA : Constant for mail saga
//  GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY  : Constant for converted value density
//  GET_RESULT_SUCCESS : Constant for result success
//  GET_RESULT_LOADING : Constant for result loading
//  GET_RESULT_ERROR : Constant for result error
//  GET_RESULT_SAGA : Constant for result saga
//  GET_INPUT_ERRORS_SUCCESS : Constant for input error success
//  GET_CALCULATE_GAS_DENSITY_SAGA : Constant for calculate gas density saga
//  GET_INPUT_ERRORS_UPDATE : Constant for input error update
//  GET_LANGUAGE_LIST_SUCCESS : Constant for language list success
//  GET_LANGUAGE_LIST_ERROR : Constant for language list error
//  GET_LANGUAGE_LIST_LOADING : Constant for language list loading
//  GET_LANGUAGE_LIST_SAGA : Constant for language list saga

const GET_LABELS_DATA_LOADING = "GET_LABELS_DATA_LOADING";
const GET_LABELS_DATA_SUCCESS = "GET_LABELS_DATA_SUCCESS";
const GET_LABELS_DATA_ERROR = "GET_LABELS_DATA_ERROR";
const GET_LABELS_DATA_SUCCESS_ROOTSAGA = "GET_LABELS_DATA_SUCCESS_ROOTSAGA";

const GET_UOMLIST_SUCCESS = "GET_UOMLIST_SUCCESS";
const GET_UOMLIST_DATA_LOADING = "GET_UOMLIST_DATA_LOADING";
const GET_UOMLIST_DATA_ERROR = "GET_UOMLIST_DATA_ERROR";
const GET_UOMLIST_SAGA = "GET_UOMLIST_SAGA";

const GET_DEFAULT_DATA_SUCCESS = "GET_DEFAULT_DATA_SUCCESS";
const GET_DEFAULT_DATA_LOADING = "GET_DEFAULT_DATA_LOADING";
const GET_DEFAULT_DATA_ERROR = "GET_DEFAULT_DATA_ERROR";
const GET_DEFAULT_DATA_SAGA = "GET_DEFAULT_DATA_SAGA";

const GET_PRESSURE_UOM_CONVERSION_DATA_SAGA =
  "GET_PRESSURE_UOM_CONVERSION_DATA_SAGA";

const GET_PRESSURE_DROP_UOM_CONVERSION_DATA_SAGA =
  "GET_PRESSURE_DROP_UOM_CONVERSION_DATA_SAGA";

const GET_CALCULATE_INPUT_DATA_SUCCESS = "GET_CALCULATE_INPUT_DATA_SUCCESS";
const GET_CALCULATE_INPUT_DATA_LOADING = "GET_CALCULATE_INPUT_DATA_LOADING";
const GET_CALCULATE_INPUT_DATA_ERROR = "GET_CALCULATE_INPUT_DATA_ERROR";

const GET_TEMPERATURE_UOM_CONVERSION_DATA_SAGA =
  "GET_TEMPERATURE_UOM_CONVERSION_DATA_SAGA";
const GET_FLOWRATE_UOM_CONVERSION_DATA_SAGA =
  "GET_FLOWRATE_UOM_CONVERSION_DATA_SAGA";
const GET_DENSITY_UOM_CONVERSION_DATA_SAGA =
  "GET_DENSITY_UOM_CONVERSION_DATA_SAGA";
const GET_FLUID_CONSTANTS_DATA_SAGA = "GET_FLUID_CONSTANTS_DATA_SAGA";

const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
const SEND_MAIL_LOADING = "SEND_MAIL_LOADING";
const SEND_MAIL_ERROR = "SEND_MAIL_ERROR";
const SEND_MAIL_SAGA = "SEND_MAIL_SAGA";

const GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY =
  "GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY";

const GET_RESULT_SUCCESS = "GET_RESULT_SUCCESS";
const GET_RESULT_LOADING = "GET_RESULT_LOADING";
const GET_RESULT_ERROR = "GET_RESULT_ERROR";
const GET_RESULT_SAGA = "GET_RESULT_SAGA";

const GET_INPUT_ERRORS_SUCCESS = "GET_INPUT_ERRORS_SUCCESS";

const GET_CALCULATE_GAS_DENSITY_SAGA = "GET_CALCULATE_GAS_DENSITY_SAGA";
const GET_INPUT_ERRORS_UPDATE = "GET_INPUT_ERRORS_UPDATE";

const GET_LANGUAGE_LIST_SUCCESS = "GET_LANGUAGE_LIST_SUCCESS";
const GET_LANGUAGE_LIST_ERROR = "GET_LANGUAGE_LIST_ERROR";
const GET_LANGUAGE_LIST_LOADING = "GET_LANGUAGE_LIST_LOADING";
const GET_LANGUAGE_LIST_SAGA = "GET_LANGUAGE_LIST_SAGA";

/* Exporting constants */
export {
  GET_LABELS_DATA_LOADING,
  GET_LABELS_DATA_SUCCESS,
  GET_LABELS_DATA_ERROR,
  GET_LABELS_DATA_SUCCESS_ROOTSAGA,
  GET_UOMLIST_SUCCESS,
  GET_UOMLIST_DATA_LOADING,
  GET_UOMLIST_DATA_ERROR,
  GET_UOMLIST_SAGA,
  GET_DEFAULT_DATA_SUCCESS,
  GET_DEFAULT_DATA_LOADING,
  GET_DEFAULT_DATA_ERROR,
  GET_DEFAULT_DATA_SAGA,
  GET_PRESSURE_UOM_CONVERSION_DATA_SAGA,
  GET_PRESSURE_DROP_UOM_CONVERSION_DATA_SAGA,
  GET_CALCULATE_INPUT_DATA_SUCCESS,
  GET_CALCULATE_INPUT_DATA_LOADING,
  GET_CALCULATE_INPUT_DATA_ERROR,
  GET_TEMPERATURE_UOM_CONVERSION_DATA_SAGA,
  GET_FLOWRATE_UOM_CONVERSION_DATA_SAGA,
  GET_DENSITY_UOM_CONVERSION_DATA_SAGA,
  GET_FLUID_CONSTANTS_DATA_SAGA,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_LOADING,
  SEND_MAIL_ERROR,
  SEND_MAIL_SAGA,
  GET_CONVERTED_VALUE_DENSITY_SPECIFIC_GRAVITY,
  GET_RESULT_SUCCESS,
  GET_RESULT_LOADING,
  GET_RESULT_ERROR,
  GET_RESULT_SAGA,
  GET_INPUT_ERRORS_SUCCESS,
  GET_CALCULATE_GAS_DENSITY_SAGA,
  GET_INPUT_ERRORS_UPDATE,
  GET_LANGUAGE_LIST_SUCCESS,
  GET_LANGUAGE_LIST_ERROR,
  GET_LANGUAGE_LIST_LOADING,
  GET_LANGUAGE_LIST_SAGA
};
