/* -- File : PhysicalDetails/index.js -- */
/* Author : SLK */
/* Description : which contains all physical details reducers */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import * as constants from "./constant";

const dropDownValues = {
  pipeSize: [
    { value: "1", description: "6 inches (150 mm)" },
    { value: "2", description: "8 inches (200 mm)" },
    { value: "3", description: "10 inches (250 mm)" }
  ],
  pressureValue: [
    { value: "1", description: "150" },
    { value: "2", description: "300" },
    { value: "3", description: "600" }
  ],
  trimType: [
    { value: "1", description: "Linear" },
    { value: "2", description: "Equal Percent" },
    { value: "3", description: "Noise Control" }
  ],
  showLoader: false
};

/* Physical details list reducer */
const getPhysicalDetailsListValues = (
  state = dropDownValues,
  action = null
) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.GET_PIPESIZE_LIST_SUCCESS:
      return {
        ...state,
        pipeSize: action.pipeSizeData,
        showLoader: false
      };

    case constants.GET_PRESSURECLASS_LIST_SUCCESS:
      return {
        ...state,
        pressureValue: action.pressureClassData,
        showLoader: false
      };

    case constants.GET_TRIMTYPE_LIST_SUCCESS:
      return {
        ...state,
        trimType: action.trimTypeData,
        showLoader: false
      };

    case constants.GET_PHYSICALDETAILS_LIST_LOADING:
      return {
        ...state,
        showLoader: true
      };
    case constants.GET_PHYSICALDETAILS_LIST_ERROR:
      return {
        ...state,
        showLoader: false
      };

    default:
      break;
  }
  /* Returning updated state */
  return state;
};

export { getPhysicalDetailsListValues };
