/* -- File : common/index.js */
/* Author : SLK */
/* Description : which contains all common reducers */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import * as constants from "./constant";
import {
  labelsState,
  UOMListValues,
  defaultValues,
  calculateInputData,
  defaultInputErrors,
  defaultResultValue
} from "../../utils/defaultData";

/* default value for pressure drop UOM */
const defaultValuesPressureDropUOM = {
  showLoader: false
};

/* default value for mail sent details */
const mailSentValue = {
  showLoader: false,
  mailSent: false
};

/* default value for language list */
const defaultlanguageList = {
  listValue: [{ locale: "en_US" }, { locale: "de_DE" }],
  showLoader: false
};

/* Labels data reducer */
const getLabelsData = (state = labelsState, action = null) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.GET_LABELS_DATA_SUCCESS:
      return action.data;
    case constants.GET_LABELS_DATA_LOADING:
      return { ...state, showLoader: true };
    case constants.GET_LABELS_DATA_ERROR:
      return { ...state, showLoader: false };
    default:
      break;
  }
  /* Returning updated state */
  return state;
};

/* UOM list reducer */
const getUOMList = (state = UOMListValues, action = null) => {
  const densityArray = [];
  const pressureArray = [];
  const flowrateLiquidArray = [];
  const flowrateGasArray = [];
  const temperatureArray = [];
  const noiseLimitArray = [];
  const pressureDropArray = [];
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.GET_UOMLIST_SUCCESS:
      action.data.forEach(item => {
        if (item.uom_for === "DENSITY") {
          densityArray.push({ value: item.unit, description: item.unit });
        } else if (
          item.uom_for === "PRESSURE" ||
          item.uom_for === "PRESSURE_GAGE"
        ) {
          pressureArray.push({ value: item.unit, description: item.unit });
        } else if (item.uom_for === "MASS_FLOW_RATE") {
          flowrateLiquidArray.push({
            value: item.unit,
            description: item.unit
          });
          flowrateGasArray.push({ value: item.unit, description: item.unit });
        } else if (item.uom_for === "VOL_FLOW_RATE_LIQ") {
          flowrateLiquidArray.push({
            value: item.unit,
            description: item.unit
          });
        } else if (item.uom_for === "VOL_FLOW_RATE_GAS") {
          flowrateGasArray.push({ value: item.unit, description: item.unit });
        } else if (item.uom_for === "TEMPERATURE") {
          temperatureArray.push({ value: item.unit, description: item.unit });
        } else if (item.uom_for === "NOISE_LIMIT") {
          noiseLimitArray.push({ value: item.unit, description: item.unit });
        } else if (item.uom_for === "PRESSURE_DROP") {
          pressureDropArray.push({ value: item.unit, description: item.unit });
        }
      });

      return {
        ...state,
        density: densityArray,
        pressure: pressureArray,
        flowrateLiquid: flowrateLiquidArray,
        flowrateGas: flowrateGasArray,
        temperature: temperatureArray,
        noiseLimit: noiseLimitArray,
        pressureDrop: pressureDropArray,
        showLoader: false
      };
    case constants.GET_UOMLIST_DATA_LOADING:
      return { ...state, showLoader: true };
    case constants.GET_UOMLIST_DATA_ERROR:
      return { ...state, showLoader: false };
    default:
      break;
  }
  /* Returning updated state */
  return state;
};

/* Default value reducer */
const getDefaultValues = (state = defaultValues, action = null) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.GET_DEFAULT_DATA_SUCCESS:
      //      action.data.showLoader = false;
      return action.data;
    case constants.GET_DEFAULT_DATA_LOADING:
      return { ...state, showLoader: true };
    case constants.GET_DEFAULT_DATA_ERROR:
      return { ...state, showLoader: false };
    default:
      break;
  }
  /* Returning updated state */
  return state;
};

/* calculated input value reducer */
const getCalcualteInputData = (state = calculateInputData, action = null) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.GET_CALCULATE_INPUT_DATA_SUCCESS:
      return {
        ...state,
        noiseLimit: action.calcualteInputData.noiseLimit,
        temperatureT1: action.calcualteInputData.temperatureT1,
        pipeSize: action.calcualteInputData.pipeSize,
        trimType: action.calcualteInputData.trimType,
        liquidName: action.calcualteInputData.liquidName,
        flowrate: action.calcualteInputData.flowrate,
        densityLiquid: action.calcualteInputData.densityLiquid,
        densityGas: action.calcualteInputData.densityGas,
        molecularWeight: action.calcualteInputData.molecularWeight,
        criticalPressurePC: action.calcualteInputData.criticalPressurePC,
        vaporPressurePV: action.calcualteInputData.vaporPressurePV,
        gasName: action.calcualteInputData.gasName,
        pressureValue: action.calcualteInputData.pressureValue,
        isDownstreamP2: action.calcualteInputData.isDownstreamP2,
        upstreamPressureP1: action.calcualteInputData.upstreamPressureP1,
        downStreamPressureP2: action.calcualteInputData.downStreamPressureP2,
        pressureChange: action.calcualteInputData.pressureChange,
        selectedTab: action.calcualteInputData.selectedTab,
        isDensity: action.calcualteInputData.isDensity,
        isDensityGas: action.calcualteInputData.isDensityGas,
        flowrateGas: action.calcualteInputData.flowrateGas,
        flowrateBaseUnit:
          action.calcualteInputData.flowrateBaseUnit !== undefined
            ? action.calcualteInputData.flowrateBaseUnit
            : "lb/h",
        showLoader: false
      };
    case constants.GET_CALCULATE_INPUT_DATA_LOADING:
      return { ...state, showLoader: true };
    case constants.GET_CALCULATE_INPUT_DATA_ERROR:
      return { ...state, showLoader: false };
    default:
      break;
  }
  /* Returning updated state */
  return state;
};

/* mail reducer */
const mailSentDetails = (state = mailSentValue, action = null) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.SEND_MAIL_SUCCESS:
      return { mailSent: action.data.message, showLoader: false };
    case constants.SEND_MAIL_LOADING:
      return { ...state, showLoader: true };
    case constants.SEND_MAIL_ERROR:
      return { ...state, showLoader: false };
    default:
      break;
  }
  /* Returning updated state */
  return state;
};

/* calculated result reducer */
const calculatedResult = (state = defaultResultValue, action = null) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.GET_RESULT_SUCCESS:
      return {
        ...state,
        showLoader: false,
        calculateCV: action.data.calculatedCvData,
        cavitationMessage: action.data.analyzeCavitationData.cavitation_msg,
        valveSizeOpenings: action.data.valveSizeOpenenings,
        analyzeNoise: action.data.analyzeNoise.noise_msg
      };
    case constants.GET_RESULT_LOADING:
      return { ...state, showLoader: true };
    case constants.GET_RESULT_ERROR:
      return { ...state, showLoader: false };
    default:
      break;
  }
  /* Returning updated state */
  return state;
};

/* Input Errors Reducer */
const inputErrors = (state = defaultInputErrors, action = null) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.GET_INPUT_ERRORS_SUCCESS:
      return {
        ...state
      };
    case constants.GET_INPUT_ERRORS_UPDATE:
      return action.inputErrorsData;
    default:
      break;
  }
  /* Returning updated state */
  return state;
};

/* languageList Reducer */
const languageList = (state = defaultlanguageList, action = null) => {
  /* Switch case to check the action type */
  /* And return the updated state if action type matches the case */
  switch (action.type) {
    case constants.GET_LANGUAGE_LIST_SUCCESS:
      return {
        listValue: action.data,
        showLoader: false
      };
    case constants.GET_LANGUAGE_LIST_ERROR:
      return { ...state, showLoader: false };
    case constants.GET_LANGUAGE_LIST_LOADING:
      return { ...state, showLoader: true };
    default:
      break;
  }
  /* Returning updated state */
  return state;
};

/* Exporting reducers */
export {
  getLabelsData,
  getUOMList,
  getDefaultValues,
  getCalcualteInputData,
  mailSentDetails,
  calculatedResult,
  inputErrors,
  languageList
};
