/* -- File : PhysicalDetails/saga.js -- */
/* Author : SLK */
/* Description : physical details saga file, which contains saga functions */
/* -- Year : 2019 --*/
/* -- Copyright: SLK Software Services Pvt Ltd -- */
import { put, call, all, select } from "redux-saga/effects";
import * as CONSTANTS from "./constant";
import * as Api from "./api";

export const getDefaultValues = state => state.getDefaultValues;

/* Saga function to get physcial details list */
export function* fetchPhysicalDetailsListValues(action) {
  /* using try and catch block to handle the error */
  try {
    yield put({ type: CONSTANTS.GET_PHYSICALDETAILS_LIST_LOADING });

    //    const pipeSizeData = yield call(Api.fetchPipeSizeListAPI, action.payload);
    //    const pressureClassData = yield call(
    //      Api.fetchPressureClassListAPI,
    //      action.payload
    //    );
    //    const trimTypeData = yield call(Api.fetchTrimTypeListAPI, action.payload);

    const [pipeSizeData, pressureClassData, trimTypeData] = yield all([
      call(Api.fetchPipeSizeListAPI, action.payload),
      call(Api.fetchPressureClassListAPI, action.payload),
      call(Api.fetchTrimTypeListAPI, action.payload)
    ]);

    const defaultData = yield select(getDefaultValues);
    if (parseInt(defaultData.pipeSize, 10) >= 24) {
      pressureClassData.map(item => {
        const data =
          parseInt(item.value, 10) >= 900 ? (item.isReadonly = true) : item;
        return data;
      });
    }

    if (parseInt(defaultData.pressureValue, 10) >= 900) {
      pipeSizeData.map(item => {
        const data =
          parseInt(item.value, 10) >= 24 ? (item.isReadonly = true) : item;
        return data;
      });
    }

    yield put({ type: CONSTANTS.GET_PIPESIZE_LIST_SUCCESS, pipeSizeData });
    yield put({
      type: CONSTANTS.GET_PRESSURECLASS_LIST_SUCCESS,
      pressureClassData
    });
    yield put({ type: CONSTANTS.GET_TRIMTYPE_LIST_SUCCESS, trimTypeData });
  } catch (e) {
    /* Dispatching failure event if any error occurs in try block */
    yield put({ type: "GET_PHYSICALDETAILS_LIST_ERROR", message: e.message });
  }
}
